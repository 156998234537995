import React,{useState} from 'react'
import {Form,Alert,Row,Col} from 'react-bootstrap'

export default function ComponentDemo(props){

    const [input,setInput]=useState(1)

    const changePaziente = (event) =>{
        const valore = event.target.value;
        if(valore.trim!==""){
            if(valore > 0 ){
                props.cambiaPaziente(valore);
                setInput(valore)
            }else{
                if(valore!=="") alert('Valore non valido');
                setInput(valore);
            }
        }else{
            setInput(valore);
        }
    }

    return(<Alert variant="info">
        <Row>
            <Col>Seleziona ID del paziente (<b>Demo</b>)</Col>
            <Col>
                <Form.Control type="number" value={input} min={1} onChange={(event)=>changePaziente(event)} />
            </Col>
        </Row>
    </Alert>)

}