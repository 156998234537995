import React,{useContext,useState} from 'react'
import Loader from '../../Loader'
import {PazienteContext} from '../../context/PazienteContext'

export default function Gestore(props){

    const[loading,setLoading] = useState(false);
    const[apiChiamata,setApiChiamata] = useState(false);
    const[errore,setErrore] = useState(false);
    const[errorMessage,setErrorMessage] = useState(null);

    const { getProtocolloInCorso , saveTraining} = useContext(PazienteContext);

    const doChiamata = () =>{
        setLoading(true)
        props.setCaricando(true)
        if(props.tipo==="getter") doGetProtocolloSessione()
        if(props.tipo==="save") doSaveTraining()

    }

    const doGetProtocolloSessione = async () =>{
        try{
            await getProtocolloInCorso(props.idpaziente).then(function(){
                successoChiamata()
            })
        }catch(error){
            erroreChiamata(error)
        }
    }

    const doSaveTraining = async () =>{
        try{
            await saveTraining(props.training).then(function(){
                successoChiamata()                
            })
        }catch(error){
            erroreChiamata(error)
        }
    }

    const successoChiamata = () =>{
        if(props.tipo==="getter") {
            props.setCaricamentoDati(false)
            props.errore(null)
            props.setCaricando(false)
        }
        if(props.tipo==="save") {
            props.setDatiInviati()
        }
        setLoading(false)
        setApiChiamata(true)
        setErrore(false)
    }

    const erroreChiamata = (errore) =>{
        if(props.tipo==="getter") {
            props.errore(errore.response.data ? errore.response.data : null)
            props.setCaricando(false)
        }
        setApiChiamata(true)
        setErrorMessage(errore.response.data ? errore.response.data : null)
        setErrore(true)
        setLoading(false)
    }

    return(loading===true ? 
            <Loader sezione="m"/>
            :
            errore===true ? 
                <>Errore : {errorMessage}</>
                :
                apiChiamata===true ? null 
                    : 
                    doChiamata()
    )
}

