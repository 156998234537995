
const cambiaSpecialitaCheck = (elencoSpecialita,event) =>{
    const nuovoElenco = []
    const id = Number(event.target.id)
    elencoSpecialita.forEach(specialita=>{
        if(specialita.idSpecialita === id){
            var object = {
                idSpecialita:specialita.idSpecialita,
                nomeSpecialita:specialita.nomeSpecialita,
                patologie:specialita.patologie,
                visibile:!specialita.visibile
            }
            nuovoElenco.push(object)
        }else{
            nuovoElenco.push(specialita)
        }
    })
    return nuovoElenco
}

const cambiaPatologiaCheck = (elencoSpecialita,event) =>{
    const nuovoElenco = []
    const id = Number(event.target.id)
    elencoSpecialita.forEach(specialita=>{
        var patologieNuove = []
        var triggered = false
        specialita.patologie.forEach(patologia=>{
            if(patologia.ID == id){
                triggered = true
                var object = {
                    ID : patologia.ID,
                    nome : patologia.nome,
                    checked : !patologia.checked
                }
                patologieNuove.push(object)
            }else{
                patologieNuove.push(patologia)
            }
        })
        if(triggered){
            var object = {
                idSpecialita:specialita.idSpecialita,
                nomeSpecialita:specialita.nomeSpecialita,
                patologie:patologieNuove,
                visibile:specialita.visibile
            }
            nuovoElenco.push(object)
        }else{
            nuovoElenco.push(specialita)
        }
    })
    return nuovoElenco
}

const elaboraObject = (specialita,dist) =>{
    const patologieObjectArray = []
    const arrayPatologie=[]
    var object
    if(!dist){
        specialita.patologia.forEach(spec=>{arrayPatologie.push(spec)})
        arrayPatologie.forEach((pato)=>{
            var patologiaObject = {
                ...pato,
                checked:false
            }
            patologieObjectArray.push(patologiaObject)
        })
        object = {
            idSpecialita:specialita.idSpecialita,
            nomeSpecialita:specialita.nomeSpecialita,
            patologie:patologieObjectArray,
            visibile : false
        }
    }else {
        specialita.patologie.forEach(spec=>{arrayPatologie.push(spec)})
        object = {
            idSpecialita:specialita.idSpecialita,
            nomeSpecialita:specialita.nomeSpecialita,
            patologie:arrayPatologie,
            visibile : false
        }
    }
    
    return object
}


export default {cambiaSpecialitaCheck , cambiaPatologiaCheck , elaboraObject  }