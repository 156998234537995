import React,{useState} from 'react'
import {Button,Alert,Row,Col} from 'react-bootstrap'
import { Link } from 'react-router-dom'
//import component
import HeaderTerapista from '../../../StaticComponents/HeaderTerapista'
import TabellaAggiungiEsercizi from '../Tabelle/TabellaAggiungiEsercizio'
import RecapProtocollo from '../Recap/RecapProtocollo'
import GestorePatologie from '../GestorePatologie/Gestore'
//import form component
import FormLabel from '../FormComponents/FormLabel'
import FormNumberLabel from '../FormComponents/FormLabelNumber'
import FormDropdown from '../FormComponents/FormDropdown'
import FormTextarea from '../FormComponents/FormTextarea'
//import opzioni dropdown
import {LivelloDifficolta,Giorni} from '../../../StaticComponents/ElenchiOpzioniDropdown'
import './Style.css'

export default function CreazioneProtocolli(props){
    const [riinizializza,setRinizializza]=useState(true)
    const [durataTotale,setDurataTotale]=useState(0)
    const [messaggioDurataTotale,setMessaggioDurataTotale]=useState("0 minuti e 0 secondi")
    const [ripetizioni,setRipetizioni]=useState(null)
    const [titolo,setTitolo]=useState("")
    const [descrizione,setDescrizione]=useState("")
    const [frequenza,setFrequenza]=useState(0)
    const [settimane,setSettimane]=useState(1)
    const [eserciziScelti,setEserciziScelti]=useState(null)
    const [vediRiepilogo,setVediRiepilogo]=useState(false)
    const [livello,setLivello]=useState("")
    const [elencoPatologie,setElencoPatologie]=useState(null)
    const [elencoSedi,setElencoSedi]=useState(null)
    const [idProtocollo,setIdProtocollo]=useState(null)
    
    const CambiaDurata = (nuovaDurata) =>{
        setDurataTotale(nuovaDurata)
        var minuti,secondi,durataMenoSecondi
        secondi=nuovaDurata%60
        durataMenoSecondi=nuovaDurata-secondi
        minuti=(durataMenoSecondi/60).toFixed(0)
        setMessaggioDurataTotale(minuti+" minuti "+secondi+" secondi")
        if(nuovaDurata>3600) alert("Attenzione,la durata complessiva degli esercizi è maggiore di 60 minuti")
    }

    const controlloSettimane = (numero) =>{
        if(numero==="") setSettimane("")
        else{
        var num=parseInt(numero)
        if(numero>=0 && numero<51) setSettimane(num)
        else alert("Il valore "+numero+" settimane non e' valido.\nRange valido : 1 - 50")}
    }

    const controlloDati= () =>{
        var messaggio="Errore/i nel/i campo/i :"
        var messaggioDurata="Inserisci la durata per l' esercizio numero:"
        var cont=1
        if(ripetizioni!==null){
            ripetizioni.forEach((ripetizione)=>{
                if(ripetizione==="") messaggioDurata+="\n"+cont+"^ esercizio"
                cont++
            })
        }
        if(titolo==="") messaggio+="\n-Titolo del protocollo"
        if(descrizione==="") messaggio+="\n-Descrizione del protocollo"
        //todo controllo almeno una patologia
        if(livello==="") messaggio+="\n-Inserisci il livello di difficolta'"
        if(frequenza===0) messaggio+="\n-Inserisci la frequenza settimanale"
        if(durataTotale===0) messaggio+="\n-Inserisci almeno un esercizio"
        if(settimane===0 || settimane==="") messaggio+="\n-Numero di settimane non valido"
        if(messaggio==="Errore/i nel/i campo/i :" && messaggioDurata==="Inserisci la durata per l' esercizio numero:") setVediRiepilogo(true)
        else {
            if(messaggioDurata!=="Inserisci la durata per l' esercizio numero:" && messaggio!=="Errore/i nel/i campo/i :") alert(messaggio+"\n"+messaggioDurata)
            else{
                if(messaggioDurata!=="Inserisci la durata per l' esercizio numero:") alert("Errore:\n"+messaggioDurata)
                if(messaggio!=="Errore/i nel/i campo/i :") alert(messaggio)
            }
        }
    }

    const inizializzazioneModifica = () =>{
        setIdProtocollo(props.protocollo.ID)
        setTitolo(props.protocollo.titolo)
        setDescrizione(props.protocollo.descrizione)
        setLivello(props.protocollo.livello)
        setFrequenza(props.protocollo.frequenza)
        setSettimane(props.protocollo.numeroSettimane)
        setRinizializza(true)
    }

    return(
        <div style={{marginLeft:"10%",marginRight:"10%"}}>
            {props.tipo==="modifica" ? null : <HeaderTerapista pagina="2"/>}
            {props.tipo==="modifica" && idProtocollo === null ? 
                inizializzazioneModifica()
                : 
                <div>
                    <br/>
                    {vediRiepilogo?<RecapProtocollo titolo={titolo} difficolta={livello} indietro={()=>setVediRiepilogo(false)} eserciziScelti={eserciziScelti} 
                    durataTotale={durataTotale} messaggioDurataTotale={messaggioDurataTotale} frequenza={frequenza} descrizione={descrizione} elencoRipetizioni={ripetizioni}
                    numeroSettimane={settimane} patologie={elencoPatologie} idProtocollo={idProtocollo}/>:
                    <div>
                        <FormLabel testo="Titolo" funzione={(valore)=>setTitolo(valore)} valore={titolo}/>
                        <FormTextarea testo="Descrizione protocollo" place="Aggiungi una descrizione" valore={descrizione} funzione={(valore)=>{setDescrizione(valore)}}/>
                        <br/>
                        <GestorePatologie elencoPatologie={elencoPatologie} aggiornaSedi={(elenco)=>setElencoSedi(elenco)} aggiornaPatologie={(elenco)=>setElencoPatologie(elenco)} sezione={props.tipo} oldPatologie={props.tipo === "modifica" ? props.protocollo.patologie : null} />
                        <br/>
                        <FormDropdown testo="Inserisci la difficoltà" testoDropdown={livello===""?"Seleziona difficolta":livello} opzioni={LivelloDifficolta} funzione={(valore)=>setLivello(valore)}/>
                        <FormDropdown testo="Inserisci il numero di giorni alla settimana" testoDropdown={frequenza===0?"Seleziona frequenza":frequenza} opzioni={Giorni} funzione={(valore)=>setFrequenza(valore)}/>
                        <FormNumberLabel testo="Numero di settimane" valore={settimane} funzione={(numero)=>controlloSettimane(numero)}/>
                        <hr/>
                        <Row><Col md="6">
                            <Alert  style={{backgroundColor:"lightgrey"}}>
                                Durata totale degli esercizi selezionati = <b>{messaggioDurataTotale}</b>
                            </Alert>
                        </Col></Row>
                        <br/>
                        <TabellaAggiungiEsercizi riinizializza={riinizializza} setRinizializza={()=>setRinizializza(!riinizializza)} 
                        pagina="CreazioneProtocolli" difficolta={livello}  setDurata={(durata)=>CambiaDurata(durata)} sezione={props.tipo} eserciziDefault={props.tipo === "modifica" ? props.protocollo.eserciziAssegnati : null}
                        eserciziScelti={eserciziScelti} setEserciziScelti={(esercizi)=>setEserciziScelti(esercizi)}
                        setRipetizioni={(elenco)=>setRipetizioni(elenco)}/>
                        {durataTotale===0?<><br/><br/></>:null}
                        <Button onClick={controlloDati} style={{width:"20%",marginLeft:"80%",backgroundColor:"#003769",color:"white"}}>CONFERMA</Button> 
                        <br/><br/>
                        <Button style={{width:"20%",marginLeft:"80%",marginBottom:"10px",backgroundColor:"#003769",color:"white"}} as={Link} to="/HomeTerapista"> HOME </Button>
                    </div>}
                </div>
        }
        </div>
    )
    
}