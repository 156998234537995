import axios from 'axios'

const apiConfig = {
    development:'http://localhost:8080/',
    production:'https://adiuvant-be.cbim.it/'
};

const env =process.env.NODE_ENV || 'development';
const baseURL = apiConfig[env];
const instance = axios.create({ baseURL});

//PATOLOGIE
const getPatologie = () =>{
    const url = 'api/patologie/get-patologie';
    return instance.post(url);
}

//ESERCIZI
const getEsercizi = () =>{
    const url = 'api/esercizi/get-esercizi';
    return instance.post(url);
}

const saveEsercizio = (esercizio) =>{
    const url = 'api/esercizi/save-esercizio';
    return instance.post(url,esercizio);
}

//PROTOCOLLI
const getProtocolli = () =>{
    const url = 'api/protocolli/get-protocolli';
    return instance.post(url);
}

const saveProtocollo = (protocollo) =>{
    const url = 'api/protocolli/save-protocollo';
    return instance.post(url,protocollo);
}

//PAZIENTI
const getPazienti = () =>{
    const url = 'api/pazienti/get-pazienti';
    return instance.post(url);
}

const getUltimoProtocollo = (idprotocollo) =>{
    const url = 'api/pazienti/get-ultimo-protocollo';
    return instance.post(url,idprotocollo)
}
 
const assegnaProtocollo = (assegnazione) =>{
    const url = 'api/pazienti/save-associazione-protocollo';
    return instance.post(url,assegnazione);
}

//PAZIENTE
const getProtocolloSessione = (paziente) =>{
    const url = 'api/pazienti/get-protocollo-sessione';
    return instance.post(url,paziente)
}

const saveTraining = (training) =>{
    const url = 'api/pazienti/save-training';
    return instance.post(url,training)
}

const getTrainingPaziente = (paziente) =>{
    const url = 'api/pazienti/get-training-paziente';
    return instance.post(url,paziente)
}

export default {
    getPatologie,
    getEsercizi,
    saveEsercizio,
    getProtocolli,
    saveProtocollo,
    getPazienti,
    getUltimoProtocollo,
    assegnaProtocollo,
    getProtocolloSessione,
    saveTraining,
    getTrainingPaziente
}