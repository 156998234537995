import React from 'react'
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom'
//esercizi
import CreaEsercizio from '../Interfaccia-Medico/Components-Medico/Menu/SelezioneMenu/CreazioneEsercizi'
import LibreriaEsercizi from '../Interfaccia-Medico/Components-Medico/Menu/SelezioneMenu/LibreriaEsercizi'
//protocolli
import CreaProtocolli from '../Interfaccia-Medico/Components-Medico/Menu/SelezioneMenu/CreazioneProtocolli'
import LibreriaProtocolli from '../Interfaccia-Medico/Components-Medico/Menu/SelezioneMenu/LibreriaProtocolli'
//gestione pazienti
import GestionePazienti from '../Interfaccia-Medico/Components-Medico/Menu/SelezioneMenu/GestionePazienti'
//pagine
import HomeDottore from '../pages/HomeDottore'
import Login from '../pages/Login'
import HomePaziente from '../pages/HomePaziente'
import Admin from '../pages/Admin'
export default function Navigation(){
return(
<Router>
        <Switch>
            {/*Pagina per il login*/}
          <Route exact path="/" component={Login}/>
          {/*Pagine per gli utenti Dottore*/}
          <Route exact path="/HomeTerapista" component={HomeDottore}/>
          <Route exact path="/CreaEsercizio" component={CreaEsercizio}/>
          <Route exact path="/LibreriaEsercizi" component={LibreriaEsercizi}/>
          <Route exact path="/CreaProtocollo" component={CreaProtocolli}/>
          <Route exact path="/LibreriaProtocolli" component={LibreriaProtocolli}/>
          <Route exact path="/GestionePazienti" component={GestionePazienti}/>
          {/*Pagine per gli utenti Pazienti*/}
          <Route exact path="/HomePaziente" component={HomePaziente}/>
          {/*Pagina per l'amministrazione delle utenze*/}
          <Route exact path="/Admin" component={Admin}/>
        </Switch>
</Router>
)
}