import React,{useState} from 'react'
import {Table,Form,Row,Col,Alert} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Util from '../Utill/UtilEsercizi'
import Pagination from './Pagination'
import DropdownButton from '../../../../components/BasicComponents/DropdownButton'
import {LivelloDifficolta,FaseTraining} from '../../../StaticComponents/ElenchiOpzioniDropdown'
//import json dati
import {EserciziConsumer} from '../../../../context/EserciziContext'
import {PatologieConsumer} from '../../../../context/PatologieContext'
export default function TabellaEsercizi(props){
    const [pagina,setPagina]=useState(1)
    const recordPerPagina = 4
    
    const [titolo,setTitolo]=useState("")
    const [difficolta,setDifficolta]=useState("")
    const [fase,setFase]=useState("")
    const elencoLivelli=[]
    const elencoFasi=[]
    elencoLivelli.push("Vedi tutti")
    elencoFasi.push("Vedi tutti")
    LivelloDifficolta.forEach((livello)=>elencoLivelli.push(livello))
    FaseTraining.forEach((fase)=>elencoFasi.push(fase))
    
    const creaTabella = () =>{
        const ESERCIZI = EserciziConsumer._currentValue.elencoEsercizi;
        const SEDI = EserciziConsumer._currentValue.elencoSedi;
        const SPECIALITA = PatologieConsumer._currentValue.elencoPatologie;

        var contaRecord = 0
        const headerTabella = []
        const tabella = []
        const rows=[]
        headerTabella.push(<th key="HeaderTitolo">Titolo</th>)
        headerTabella.push(<th key="HeaderPatologia">Patologie</th>)
        headerTabella.push(<th key="HeaderLivello">Difficoltà</th>)
        headerTabella.push(<th key="HeaderFase">Fase Training</th>)
        headerTabella.push(Util.getHeaderSedeTabella(SEDI));
        headerTabella.push(<th key="HeaderInfo">Info</th>)
        tabella.push(<thead key="h"><tr key="Header">{headerTabella}</tr></thead>)

        ESERCIZI.forEach((esercizio)=>{ 
            const checkFiltri = Util.getCheckFiltriEsercizi({filtroTitolo:titolo , filtroDifficolta:difficolta , filtroFase:fase ,esercizio:esercizio});
            const patologieEsercizio = Util.getMessaggioPatologie(SPECIALITA,esercizio.patologie);
            if(checkFiltri===true){
                        contaRecord++
                        const rowTabella = []
                        if((recordPerPagina*pagina)-recordPerPagina+1<=contaRecord && contaRecord<=(recordPerPagina*pagina)) {
                        rowTabella.push(<td key={"Titolo"+esercizio.ID}>{esercizio.titolo}</td>)
                        rowTabella.push(<td key={"Patologia"+esercizio.ID}>{patologieEsercizio}</td>)
                        rowTabella.push(<td key={"Livello"+esercizio.ID}>{esercizio.livello}</td>)
                        rowTabella.push(<td key={"Fase"+esercizio.ID}>{esercizio.faseTraining}</td>)
                        rowTabella.push(Util.getBodySedeTabella(SEDI,esercizio));
                        rowTabella.push(<td key={"Info"+esercizio.ID} role="button" onClick={()=>props.setEsercizio(esercizio)}>
                            <FontAwesomeIcon icon={faInfoCircle}/>
                        </td>)
            rows.push(<tr key={"Row"+esercizio.ID}>{rowTabella}</tr>)}
            }
        })
        tabella.push(<tbody key="b">{rows}</tbody>)

        return(
        <>
        <Row>
            <Col>
            <Form.Control placeholder="Titolo" onChange={(event)=>setTitolo(event.target.value)} value={titolo}/>
            </Col>
            <Col>
                <DropdownButton opzioni={elencoLivelli} stile={{backgroundColor:"#003769",color:"white",width:"100%"}} testoPredefinito="Seleziona livello" funzione={(livello)=>setDifficolta(livello)}/>
            </Col>
            <Col>
                <DropdownButton opzioni={elencoFasi} stile={{backgroundColor:"#003769",color:"white",width:"100%"}} testoPredefinito="Seleziona fase" funzione={(fase)=>setFase(fase)}/>
            </Col>
        </Row>
        <br/>
            {contaRecord > 0 ?
            <div>
                <Table striped hover bordered>{tabella}</Table>
                <Pagination recordTotali={contaRecord} recordPerPagina={recordPerPagina} setPagina={(pag)=>setPagina(pag)}/>
            </div>
            :
            <Alert variant="danger">Nessuna corrispondenza trovata con i parametri selezionati</Alert>
            }
        </>
        )
    }

    return(
    <>{creaTabella()}</>)}