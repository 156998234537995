import React,{useState} from 'react'
import {Table,Form,Row,Col,Button,Alert} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle,faPlus } from '@fortawesome/free-solid-svg-icons';
//import components
import Pagination from './Pagination'
import DropdownButton from '../../../../components/BasicComponents/DropdownButton'
import {EserciziConsumer} from '../../../../context/EserciziContext'
import {PatologieConsumer} from '../../../../context/PatologieContext'
import {LivelloDifficolta,FaseTraining} from '../../../StaticComponents/ElenchiOpzioniDropdown'
import ElencoEserciziSelezionati from './ElencoEserciziSelezionati'
import UtilEsercizi from '../Utill/UtilEsercizi'
import InfoEsercizio from '../ModalComponent/InfoEsercizio'

var eserciziAggiunti = []
var ripetizioni = []
export default function TabellaAggiungiEsercizio(props){
    const [esercizioInfo,setEsercizioInfo]=useState(0)
    const [defaultDone,setDefaultDone] = useState(false)
    //AGGIUNGI ESERCIZI
    const [aggiungi,setAggiungi]=useState(true)
    const [rerender,setRerender]=useState(false)
    //PAGINATION
    const [pagina,setPagina]=useState(1)
    const recordPerPagina = 3
    //FILTRI
    const [titolo,setTitolo]=useState("")
    const [difficolta,setDifficolta]=useState("")
    const [fase,setFase]=useState("")
    const elencoLivelli=[]
    const elencoFasi=[]
    elencoLivelli.push("Vedi tutti")
    elencoFasi.push("Vedi tutti")
    LivelloDifficolta.forEach((livello)=>elencoLivelli.push(livello))
    FaseTraining.forEach((fase)=>elencoFasi.push(fase))
    //TENERE TRACCIA DEGLI ESERCIZI GIA' INSERITI
    const [mostraEsercizi,setMostraEsercizi]=useState(props.eserciziScelti)
    const [ripetizioniPerEsercizio,setRipetizioniPerEsercizio]=useState(ripetizioni)

    const aggiungiEsercizio = (esercizio) =>{
        if(props.riinizializza) {
            eserciziAggiunti=[]
            ripetizioni=[]
            props.setRinizializza()
        }
        //se puo selezionare lo stesso esercizio una sola volta eserciziAggiunti.push(esercizio)
        eserciziAggiunti.push(esercizio)
        ripetizioni.push(1)
        setMostraEsercizi(eserciziAggiunti)
        props.setEserciziScelti(eserciziAggiunti)
        setRipetizioniPerEsercizio(ripetizioni)
        props.setRipetizioni(ripetizioni)
        getDurataTotaleProtocollo()
        setRerender(!rerender)
    }//aggiungi

    const rimuovi = (posizione) =>{
        const newElenco=[]
        const newRipetizioni=[]
        var contatore=0
        //gestione ripetizione
        ripetizioni.forEach((ripetizione)=>{
            if(contatore!==posizione) newRipetizioni.push(ripetizione)
            contatore++
        })
        contatore=0
        //gestione oggetto esercizio
        mostraEsercizi.forEach((esercizio)=>{
            if(contatore!== posizione) newElenco.push(esercizio)
            contatore++
        })
        eserciziAggiunti=[]
        newElenco.forEach((esercizio)=>eserciziAggiunti.push(esercizio))
        setMostraEsercizi(newElenco)
        var durataTotale=0
        ripetizioni=[]
        newRipetizioni.forEach((ripetizione)=>ripetizioni.push(ripetizione))
        setRipetizioniPerEsercizio(ripetizioni)
        newElenco.forEach((esercizio)=>durataTotale+=esercizio.Durata)
        props.setDurata(durataTotale)
        props.setEserciziScelti(newElenco)
        props.setRipetizioni(newRipetizioni)
        getDurataTotaleProtocollo()
    }//rimuovi

    const moveUp = (posizione,esercizioUp) =>{
        var newArray = []
        var contatore=0
        //gestione ripetizioni
        var newRipetizioni = []
        var valoreRipetizioneAttuale
        //trovare valore ripetizione attuale
        ripetizioni.forEach((ripetizione)=>{
            if(posizione===contatore) valoreRipetizioneAttuale=ripetizione
            contatore++
        })
        contatore=0
        //scambiare le ripetizioni
        ripetizioni.forEach((ripetizione)=>{
            if(contatore===posizione-1){
                newRipetizioni.push(valoreRipetizioneAttuale)
                newRipetizioni.push(ripetizione)
            }else{
                if(contatore!==posizione) newRipetizioni.push(ripetizione)
            }
            contatore++
        })
        contatore=0
        //gestione oggetto esercizio
        mostraEsercizi.forEach((esercizio)=>{
            if(contatore===posizione-1) {
                newArray.push(esercizioUp)
                newArray.push(esercizio)
            }else{
                if(contatore!==posizione) newArray.push(esercizio)
            }
            contatore++
            })
        ripetizioni = newRipetizioni
        setRipetizioniPerEsercizio(newRipetizioni)
        props.setRipetizioni(newRipetizioni)
        setMostraEsercizi(newArray)
        props.setEserciziScelti(newArray)
    }//moveUp

    const moveDown = (posizione,esercizioDown) =>{
        var newArray = []
        var contatore=0
        //gestione ripetizioni
        var newRipetizioni = []
        var valoreRipetizioneAttuale
        //trovare valore ripetizione attuale
        ripetizioni.forEach((ripetizione)=>{
            if(posizione===contatore) valoreRipetizioneAttuale=ripetizione
            contatore++
        })
        contatore=0
        //scambiare le ripetizioni
        ripetizioni.forEach((ripetizione)=>{
            if(contatore===posizione+1){
                newRipetizioni.push(ripetizione)
                newRipetizioni.push(valoreRipetizioneAttuale)
            }else{
                if(contatore!==posizione) newRipetizioni.push(ripetizione)
            }
            contatore++
        })
        contatore=0
        //gestione oggetto esercizi
        mostraEsercizi.forEach((esercizio)=>{
            if(contatore===posizione+1) {
                newArray.push(esercizio)
                newArray.push(esercizioDown)
            }else{ 
                if(contatore!==posizione && contatore!==posizione+1) newArray.push(esercizio)
            }
            contatore++
        })
        ripetizioni = newRipetizioni
        setRipetizioniPerEsercizio(newRipetizioni)
        props.setRipetizioni(newRipetizioni)
        setMostraEsercizi(newArray)
        props.setEserciziScelti(newArray)
    }//moveDown

    const creaTabella = () =>{
        const ESERCIZI = EserciziConsumer._currentValue.elencoEsercizi
        const SPECIALITA = PatologieConsumer._currentValue.elencoPatologie;
        var contaRecord = 0
        const headerTabella = []
        const tabella = []
        const rows=[]
        headerTabella.push(<th key="HeaderTitolo">Titolo</th>)
        headerTabella.push(<th key="HeaderPatologia">Patologia</th>)
        headerTabella.push(<th key="HeaderLivello">Difficoltà</th>)
        headerTabella.push(<th key="HeaderFase">Fase Training</th>)
        headerTabella.push(<th key="HeaderTesta">Info</th>)
        headerTabella.push(<th key="HeaderAggiungi">Aggiungi</th>)
        tabella.push(<thead key="h"><tr key="Header">{headerTabella}</tr></thead>)

        ESERCIZI.forEach((esercizio)=>{
            const checkValido = UtilEsercizi.getCheckFiltriEsercizi({esercizio:esercizio,filtroTitolo:titolo,filtroDifficolta:difficolta,filtroFase:fase})
            const patologieEsercizio = UtilEsercizi.getMessaggioPatologie(SPECIALITA,esercizio.patologie);
            if(checkValido){
                contaRecord++
                if((recordPerPagina*pagina)-recordPerPagina+1<=contaRecord && contaRecord<=(recordPerPagina*pagina)){
                    const rowTabella = []
                    if((recordPerPagina*pagina)-recordPerPagina+1<=contaRecord && contaRecord<=(recordPerPagina*pagina)) {
                    rowTabella.push(<td key={"Titolo"+esercizio.ID}>{esercizio.titolo}</td>)
                    rowTabella.push(<td key={"Patologia"+esercizio.ID}>{patologieEsercizio}</td>)
                    rowTabella.push(<td key={"Livello"+esercizio.ID}>{esercizio.livello}</td>)
                    rowTabella.push(<td key={"Fase"+esercizio.ID}>{esercizio.faseTraining}</td>)
                    rowTabella.push(<td key={"Info"+esercizio.ID} role="button" onClick={()=>setEsercizioInfo(esercizio)}><FontAwesomeIcon icon={faInfoCircle}/></td>)
                    rowTabella.push(<td key={"Add"+esercizio.ID} role="button" onClick={()=>aggiungiEsercizio(esercizio)}><FontAwesomeIcon icon={faPlus}/></td>)
                    rows.push(<tr key={"Row"+esercizio.ID}>{rowTabella}</tr>)}  
                }
            }
        })
        tabella.push(<tbody key="b">{rows}</tbody>)

        return(
        <>
        {aggiungi?<><Button onClick={()=>setAggiungi(!aggiungi)} style={{width:"20%",backgroundColor:"#003769",color:"white"}}>AGGIUNGI ESERCIZI</Button><br/></>:
        <>
            <Button onClick={()=>setAggiungi(!aggiungi)} style={{width:"20%",backgroundColor:"#003769",color:"white"}}>CHIUDI</Button>
            <br/><br/>
            <Row>
            <Col>
                <Form.Control placeholder="Titolo Esercizio" onChange={(event)=>setTitolo(event.target.value)} value={titolo}/>
            </Col>
            <Col>
                <DropdownButton opzioni={elencoLivelli} stile={{backgroundColor:"#003769",color:"white",width:"100%"}} testoPredefinito="Seleziona livello" funzione={(livello)=>setDifficolta(livello)}/>
            </Col>
            <Col>
                <DropdownButton opzioni={elencoFasi} stile={{backgroundColor:"#003769",color:"white",width:"100%"}} testoPredefinito="Seleziona fase" funzione={(fase)=>setFase(fase)}/>
            </Col>
        </Row>
        <br/>
            {contaRecord > 0 ?
            <div>
                <Table striped hover bordered>{tabella}</Table>
                <Pagination recordTotali={contaRecord} recordPerPagina={recordPerPagina} setPagina={(pag)=>setPagina(pag)}/>
            </div>
            :
            <Alert variant="danger">Nessuna corrispondenza trovata con i parametri selezionati</Alert>
            }
        </>}
        </>
        )
    }//creaTabella

    const aggiornaRipetizione = (posizione,valore) =>{
        var newRipetizioni=[]
        var contatore=0
        ripetizioni.forEach((ripetizione)=>{
            if(contatore===posizione) newRipetizioni.push(valore)
            else newRipetizioni.push(ripetizione)
            contatore++
        })
        ripetizioni=[]
        newRipetizioni.forEach((ripetizione)=>ripetizioni.push(ripetizione))
        setRipetizioniPerEsercizio(ripetizioni)
        props.setRipetizioni(newRipetizioni)
        getDurataTotaleProtocollo()
    }//aggiornaRipetizione

    const getDurataTotaleProtocollo = () =>{
        var totaleSecondi=0
        var contatore=0
        //trovare i secondi totali
        eserciziAggiunti.forEach((esercizio)=>{
            var numeroRipetizioni
            ripetizioni[contatore]===""?numeroRipetizioni=0:numeroRipetizioni=ripetizioni[contatore]
            totaleSecondi=totaleSecondi+esercizio.durata*numeroRipetizioni
            contatore++
        })
        props.setDurata(totaleSecondi)
    }

    const aggiungiDefaultEsercizi = () =>{
        if(props.riinizializza) {
            eserciziAggiunti=[]
            ripetizioni=[]
            props.setRinizializza()
        }
        const ESERCIZI = EserciziConsumer._currentValue.elencoEsercizi

        props.eserciziDefault.forEach(esercizioAssociazione =>{
            ripetizioni.push(esercizioAssociazione.ripetizioni)
            ESERCIZI.forEach(esercizio=>{
                if(esercizio.ID === esercizioAssociazione.idEsercizio) eserciziAggiunti.push(esercizio)
            })
        })
        
        setMostraEsercizi(eserciziAggiunti)
        props.setEserciziScelti(eserciziAggiunti)
        setRipetizioniPerEsercizio(ripetizioni)
        props.setRipetizioni(ripetizioni)
        props.setRinizializza()
        getDurataTotaleProtocollo()
        setDefaultDone(true)
    }//aggiungi

    return(
        <div>
        {props.sezione === "modifica" && defaultDone===false && props.eserciziScelti === null ? aggiungiDefaultEsercizi()
        :
        <div>
            {creaTabella()}
            <br/>
            <ElencoEserciziSelezionati esercizi={mostraEsercizi} ripetizioni={ripetizioniPerEsercizio}
            rimuovi={(posizione)=>rimuovi(posizione)} aggiornaRipetizione={(posizione,valore)=>aggiornaRipetizione(posizione,valore)}
            moveUp={(posizione,esercizioUp)=>moveUp(posizione,esercizioUp)} moveDown={(posizione,esercizioDown)=>moveDown(posizione,esercizioDown)} 
            />
            {esercizioInfo!==0?<><InfoEsercizio distinzione="aggiungi" esercizio={esercizioInfo} funzione={()=>setEsercizioInfo(0)} 
            aggiungiEsercizio={(esercizio)=>aggiungiEsercizio(esercizio)} elenco={mostraEsercizi}/></>:null}
        </div>
        }
    </div>
    )
}