import React,{useState} from 'react'
import Gestore from './Gestore'
import Tabella from './TabellaTraining'

export default function SelectComponent(props){

    const [vediTabella,setVediTabella] = useState(false);

    return (
        <div>
            {
                vediTabella ? 
                    <Tabella {...props}/> 
                    :
                    <Gestore paziente={props.paziente} fine={()=>setVediTabella(true)} />
            }
        </div>
    )
}