import React,{useState} from 'react'
import {Table,Form,Alert} from 'react-bootstrap'
import Pagination from './Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {PazientiConsumer} from '../../../../context/PazientiContext';
import UtilGestione from '../Utill/UtilGestionePazienti'

export default function TabellaPazienti(props){
    //hooks filtro
    const [filtro,setFiltro]=useState("")
    //hooks pagination
    const [pagina,setPagina]=useState(1)
    const recordPerPagina = 8

    const getTabella = () =>{
        var contaRecord = 0

        const header = UtilGestione.getHeaderTabella();
        const body=[]
        
        PazientiConsumer._currentValue.elencoPazienti.forEach((paziente)=>{
            if(UtilGestione.controlloFiltri(paziente,filtro)){
                contaRecord++
                if((recordPerPagina*pagina)-recordPerPagina+1<=contaRecord && contaRecord<=(recordPerPagina*pagina)){
                    const row=[]
                    row.push(UtilGestione.getPazienteTabella(paziente));
                    row.push(<td key={"PInfo"+paziente.id} role="button" onClick={()=>props.setInfoPaziente(paziente)}><FontAwesomeIcon icon={faInfoCircle}/></td>)
                    body.push(<tr key={"PRow"+paziente.id}>{row}</tr>)
                }
        }
        })

        return(
        <div>
            {contaRecord>0  ?
            <div>
                <Table striped bordered size="sm" >
                    <thead key="TH">
                        {header}
                    </thead>
                    <tbody key="TB">
                        {body}
                    </tbody>
                </Table>
                <Pagination recordTotali={contaRecord} recordPerPagina={recordPerPagina} setPagina={(pag)=>setPagina(pag)}/>
            </div>
            :
            <Alert variant="danger">Nessuna corrispondenza trovata con i parametri selezionati</Alert>
            }
        </div>
        )
    }

    return(
        <div>
          <Form.Control placeholder="Nome & Cognome / CodiceFiscale" onChange={(event)=>setFiltro(event.target.value)} 
                        value={filtro} style={{width:"33%"}}/>
          <br/>
          {getTabella()}
        </div>
    )
}