import React, { Component } from 'react';
import {Row,Col} from 'react-bootstrap'
import CollegamentiMenu from '../StaticComponents/CollegamentiMenu'
export default class MenuDottore extends Component{

    render(){
        return(
            <div>
                <Row>
                    <Col>
                        <CollegamentiMenu pagina="/CreaEsercizio" testo="Crea Esercizio" sezione="Medico" icon="aggiungi"/>
                    </Col>
                    <Col>
                        <CollegamentiMenu pagina="/LibreriaEsercizi" testo="Libreria Esercizi" sezione="Medico" icon="libreria"/>
                    </Col>
                </Row>
                <Row>
                <Col>
                    <CollegamentiMenu pagina="/CreaProtocollo" testo="Crea Protocollo" sezione="Medico" icon="aggiungi"/>
                </Col>
                <Col>
                    <CollegamentiMenu pagina="/LibreriaProtocolli" testo="Libreria Protocolli" sezione="Medico" icon="libreria"/>
                </Col>
                </Row>
            </div>
        );
    }
}