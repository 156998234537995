import React from 'react'

const getHeaderTabella = () =>{
    const header=[]
    const rowHeader=[]
    rowHeader.push(<th key="HeaderTitolo">Titolo</th>)
    rowHeader.push(<th key="HeaderPatologia">Patologia</th>)
    rowHeader.push(<th key="HeaderDifficolta">Difficoltà</th>)
    rowHeader.push(<th key="HeaderFase">Fase</th>)
    rowHeader.push(<th key="HeaderRipetizioni">Ripetizioni</th>)
    rowHeader.push(<th key="HeaderInfo">Info</th>)
    header.push(<tr key="RowHeaderTabellaEsercizi">{rowHeader}</tr>)
    return <thead key="HeaderTabellaEsercizi">{header}</thead>
}

const getPatologieText = (PATOLOGIE_PROTOCOLLO,SPECIALITA) =>{
    const body=[]
    SPECIALITA.forEach((specialita)=>{
        PATOLOGIE_PROTOCOLLO.forEach(assegnata =>{
            const PATOLOGIE = specialita.patologia
            const bodySpecialita = []
            PATOLOGIE.forEach(patologia=>{
                if(patologia.ID === assegnata.idPatologia) bodySpecialita.push(<li key={patologia.ID+"patologia"}>{patologia.nome}</li>)
            })
            if(bodySpecialita.length>0){
                body.push(<div key={"Specialita"+specialita.idSpecialita}>
                    Patologie della specialità <b>{" "+specialita.nomeSpecialita}</b>
                    <br/>{bodySpecialita}<br/>
                </div>)
            }
        })
    })
    return body
}


export default {getHeaderTabella,getPatologieText}