import React, { Component } from 'react';

import api from '../api';

const PazienteContext = React.createContext();

const INITIAL_STATE = { protocollo:null , idPaziente:null , ultimoSalvataggio:null};

class PazienteProvider extends Component {
  state = INITIAL_STATE;


    getProtocolloInCorso = async (idPaziente) =>{
      var objectRequest = {idpaziente:idPaziente};
      const { data } = await api.getProtocolloSessione(objectRequest);
      var Protocollo = data.nameValuePairs;
      this.setState( { protocollo:Protocollo , idPaziente:idPaziente} );
    }

    saveTraining = async (training) =>{
        this.setState({ultimoSalvataggio : training})
        await api.saveTraining(training)
    }

  render() {
    return (
      <PazienteContext.Provider
        value={{...this.state , getProtocolloInCorso:this.getProtocolloInCorso ,saveTraining:this.saveTraining }}
      >
        {this.props.children}
      </PazienteContext.Provider>
    )
  }
}
const PazienteConsumer = PazienteContext.Consumer;

export { PazienteProvider , PazienteConsumer , PazienteContext }