import React,{useState} from 'react'
import {EserciziConsumer} from '../../../../../context/EserciziContext'
import {PatologieConsumer} from '../../../../../context/PatologieContext'
import {Alert,Row,Col, Table} from 'react-bootstrap'
import UtilEsercizi from '../../Utill/UtilEsercizi'
import Visualizza from '../VisualizzaDato'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle,faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export default function InfoUltimoProtocollo(props){
    
    const getPatologie = () =>{
    const SPECIALITA = PatologieConsumer._currentValue.elencoPatologie
    const PATOLOGIE_PROTOCOLLO = props.protocollo.patologie
    const body = UtilEsercizi.getPatologieText(PATOLOGIE_PROTOCOLLO,SPECIALITA);
    return  <div>
                <Alert variant="info">Elenco patologie associate</Alert><br/>
                <h5>{body}</h5>
            </div>
    }

    const getEsercizi = () =>{
        const ESERCIZI = EserciziConsumer._currentValue.elencoEsercizi;
        const ESERCIZI_ASSEGNATI = props.protocollo.eserciziAssegnati;
        const SPECIALITA = PatologieConsumer._currentValue.elencoPatologie;
        const tabella=[]
        const ripetizioni=[]
        //body
        var elencoEserciziProtocollo=[]
        ESERCIZI_ASSEGNATI.forEach(assegnazione=>{
            ripetizioni.push(assegnazione.ripetizioni)
            ESERCIZI.forEach(esercizio=>{
                if(esercizio.ID === assegnazione.idEsercizio) {
                    elencoEserciziProtocollo.push(esercizio)
                }
            })
        })
        const rowsBody=[]
        
        var contatore=0
        elencoEserciziProtocollo.forEach((es)=>{
            const rowBody=[]
            rowBody.push(<td key={"Titolo"+es.ID}>{es.titolo}</td>)
            rowBody.push(<td key={"Patologia"+es.ID}>{UtilEsercizi.getMessaggioPatologie(SPECIALITA,es.patologie)}</td>)
            rowBody.push(<td key={"Difficolta"+es.ID}>{es.livello}</td>)
            rowBody.push(<td key={"Fase"+es.ID}>{es.faseTraining}</td>)
            rowBody.push(<td key={"Ripetizioni"+es.ID}>{ripetizioni[contatore]}</td>)
            rowBody.push(<td key={"Info"+es.ID} role="button" onClick={()=>props.infoEsercizio(es)}><FontAwesomeIcon icon={faInfoCircle}/></td>)

            rowsBody.push(<tr key={"Esercizio"+es.ID}>{rowBody}</tr>)
            contatore++
        });
        tabella.push(<tbody key="BodyTabellaEsercizi">{rowsBody}</tbody>)
        return tabella
    }

    return (<div>
        <br/>
        <h4>
            <Row>
                <Col md="7">
                    <Visualizza nome="Titolo" valore={props.protocollo.titolo} tipo={1}/>
                    <Visualizza nome="Descrizione" valore={props.protocollo.descrizione} tipo={1}/>
                    <Visualizza nome="Livello" valore={props.protocollo.livello} tipo={1}/>
                    <Visualizza nome="Frequenza settimanale" valore={props.protocollo.frequenza>1?props.protocollo.frequenza+" "+"giorni":"1 giorno"} tipo={1}/>
                    <Visualizza nome="Numero settimane" valore={props.protocollo.numeroSettimane} tipo={1}/>
                </Col>
                <Col>
                {getPatologie()}
                </Col>
            </Row>
        </h4>
        <Table striped hover bordered>
            <thead>
                <tr key="header">
                    <th key="titolo">Titolo</th>
                    <th key="patologie">Patologie</th>
                    <th key="livello">Livello</th>
                    <th key="fase">Fase training</th>
                    <th key="ripetizioni">Ripetizioni</th>
                    <th key="info">Info</th>
                </tr>
            </thead>
            {getEsercizi()}
        </Table>
    </div>)
}