import React,{Component} from 'react'
import {Button,Alert,Row,Col,Form} from 'react-bootstrap'
import { Link } from 'react-router-dom'
//import component
import HeaderTerapista from '../../../StaticComponents/HeaderTerapista'
import GestorePatologie from '../GestorePatologie/Gestore'
import UtilEsercizi from '../Utill/UtilEsercizi'
import RecapEsercizio from '../Recap/RecapEsercizio'
//formComponent
import FormLabel from '../FormComponents/FormLabel'
import FormDropdown from '../FormComponents/FormDropdown'
import FormTextarea from '../FormComponents/FormTextarea'
import FormLabelNumber from '../FormComponents/FormLabelNumber'
import FormCheckbox from '../FormComponents/FormCheckbox'
import {LivelloDifficolta,FaseTraining} from '../../../StaticComponents/ElenchiOpzioniDropdown'
//CSS
import './Style.css'
export default class CreazioneEsercizi extends Component{
    constructor(){
        super()
        this.state={
            titolo:"",
            elencoPatologie:null,
            livelloDifficolta:"",
            faseTraining:"",
            durata:1,
            testa:false,
            collo:false,
            spalle:false,
            braccia:false,
            gambe:false,
            istruzioni:"",
            descrizione:"",
            video:"",
            nomeFileVideo:"",
            sedi:null,
            saveObject:null,
            objectFile:null
        }
    }

    checkBoxChanged = (event) => {
        const {id} = event.target
        this.setState(prevState=>({[id]:!prevState[id]}))
    }

    inputVideoChanged = (event) => {
        var fileObject = document.getElementById("video").files[0]
        this.setState({video:event.target.value})
            var getName = event.target.value.split('')
            var FileName =""
            getName.forEach(char=>{
                if(char!=="\\") {
                    if(char!==":") FileName=FileName+char
                }else FileName = ""
            })
            this.setState({nomeFileVideo:FileName,objectFile:fileObject})
    }

    inputNumberChanged = (numero) =>{
        var num = parseInt(numero)
        if(numero===""){
            this.setState({durata:""})
        }else{
        if(num>0 && num<61)
            this.setState({durata:num})
        else alert(numero+" valore non valido\nrange valido : 1 -> 60")
    }
    }
    invio = () =>{
        var oggetto = UtilEsercizi.getCheckInvioEsercizio(this.state)
        if(!oggetto.valido) alert(oggetto.messaggio)
        else this.setState({saveObject:oggetto})
    }

    render(){
        return(
            <div style={{marginLeft:"10%",marginRight:"10%"}}>
                <HeaderTerapista pagina="1"/>
                {this.state.saveObject!==null ? 
                    <RecapEsercizio esercizio={this.state} fileVideo={this.state.objectFile} indietro={()=>this.setState({saveObject:null})} />
                :
                <div>{/*FORM*/}    
                    <br/>
                    <FormLabel testo="Titolo esercizio" funzione={(valore)=>this.setState({titolo:valore})} valore={this.state.titolo}/>
                    <hr/>
                    <FormTextarea testo="Descrizione esercizio" place="Aggiungi una descrizione" valore={this.state.descrizione} funzione={(valore)=>{this.setState({descrizione:valore})}}/>
                    <br/>     
                    <GestorePatologie elencoPatologie={this.state.elencoPatologie} aggiornaSedi={(elenco)=>{this.setState({sedi:elenco})}} aggiornaPatologie={(elenco)=>{this.setState({elencoPatologie:elenco})}} />
                    <br/>
                    <FormDropdown testo="Livello di difficoltà" testoDropdown={this.state.livelloDifficolta===""?"Imposta il livello":this.state.livelloDifficolta} 
                    opzioni={LivelloDifficolta} funzione={(livello)=>this.setState({livelloDifficolta:livello})}/>
                    <hr/>
                    <FormDropdown testo="Fase di training" testoDropdown={this.state.faseTraining===""?"Imposta la fase":this.state.faseTraining} opzioni={FaseTraining} funzione={(fase)=>this.setState({faseTraining:fase})}/>
                    <hr/>
                    <Row>
                        <Col>
                        <Alert  style={{backgroundColor:"lightgrey"}}>Inserisci la/e sede/i interessata/e</Alert>
                        </Col>
                        <Col>
                            <Alert>
                                <FormCheckbox identificativo="testa" checked={this.state.testa} funzione={this.checkBoxChanged} testo="Testa" nomeClasse="checkBoxUno"/>
                                <FormCheckbox identificativo="collo" checked={this.state.collo} funzione={this.checkBoxChanged} testo="Collo" nomeClasse="checkBox"/>
                                <FormCheckbox identificativo="spalle" checked={this.state.spalle} funzione={this.checkBoxChanged} testo="Spalle" nomeClasse="checkBox"/>
                                <FormCheckbox identificativo="braccia" checked={this.state.braccia} funzione={this.checkBoxChanged} testo="Braccia" nomeClasse="checkBox"/>
                                <FormCheckbox identificativo="gambe" checked={this.state.gambe} funzione={this.checkBoxChanged} testo="Gambe" nomeClasse="checkBox"/>
                            </Alert>
                        </Col>
                    </Row>
                    <hr/>
                    <FormLabelNumber testo="Durata dell'esercizio in  secondi" funzione={(valore)=>this.inputNumberChanged(valore)} valore={this.state.durata} />
                    <hr/>
                    <FormTextarea testo="Istruzioni Esercizio" place="Inserisci le istruzioni" valore={this.state.istruzioni} funzione={(valore)=>{this.setState({istruzioni:valore})}}/>
                    <hr/>
                    <Row>
                        <Col>
                            <Alert  style={{backgroundColor:"lightgrey"}}>Inserisci Video</Alert>
                        </Col>
                        <Col>
                            <Form.Label htmlFor="video" className={this.state.video===""?"labelVideoError":"labelVideo"}>
                                {this.state.video===""?<span className="messaggioLabelVideo">Seleziona video</span>:<span className="messaggioLabelVideo">{this.state.nomeFileVideo}</span>} 
                            </Form.Label>
                            <input type="file" id="video" accept="video/mp4,video/x-m4v,video/*" style={{display:"none"}} onChange={this.inputVideoChanged} />
                        </Col>
                    </Row>
                    <hr/>
                    <br/>
                    <Button style={{width:"20%",marginLeft:"80%",backgroundColor:"#003769",color:"white"}} onClick={()=>this.invio()}>CONFERMA</Button>
                    <br/><br/>
                    <Link to="/HomeTerapista">
                        <Button style={{width:"20%",marginLeft:"80%",backgroundColor:"#003769",color:"white"}}>HOME</Button>
                    </Link>
                </div>}
                    <br/><br/>
            </div>
        )
    }
}