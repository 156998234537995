import React from 'react'
import {Button, Row,Col,Container} from 'react-bootstrap'
import './Style.css'

export default function FormRegistrazione(props){

    return(
        <div>
            form registrazione
            <Button variant="danger" onClick={props.setSceltaMenu}>INDIETRO</Button>
        </div>
    )
}
