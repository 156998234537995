import React,{useState} from 'react'
import VimeoPlayer from './VimeoPlayer'
import VimeoController from './VimeoController'
import {Spinner,Alert} from 'react-bootstrap'
import './VimeoComponent.css'
export default function VimeoComponent(props){

    var timer = null;

    const [playing,setPlaying] = useState(false);
    const [ready,setReady] = useState(false);
    const [restart,setRestart] = useState(false);
    const [ripeti,setRipeti] = useState(false);
    const [errore,setErrore] = useState(false);
    const [lastUrl,setLastUrl] = useState(null);

    const cambiaStato = () =>{
        
        if(playing) Pausa()
        else Play()
    }

    const Play = () =>{
        setPlaying(true);
        if(props.sezione==="paziente" && props.finito===false){
            if(props.inizio === 0) props.setInizio(new Date())
            var durata = props.esercizio.durata * 1000;
            if(timer===null) impostaTimer(durata);
        }
    }

    const Pausa = () =>{
        setPlaying(false);
    }

    const impostaTimer = (durata) =>{
        var parametroDurata = durata;
        const thisEsercizio = props.esercizio;
        timer = setTimeout(function(){
            props.prossimoTraining(thisEsercizio);
        },parametroDurata);
    }

    const checkTimer = () =>{
        if(props.annullaTimer){
            clearTimeout(timer);
            props.setAnnullaTimer();
            timer=null
        }
    }

    const Ready = () =>{
        if(playing) setPlaying(false);
        setReady(true);
    }

    const impostaPausa = () =>{
        setPlaying(false);
        setReady(true);
    }

    const refreshReady = () =>{
        setReady(false);
        setLastUrl(props.url);
    }

    return (
        <div>
            {props.pagina === "paziente" && props.url !== lastUrl ? refreshReady() : null }
            {props.finito === true && props.pagina==="paziente" && ready === false ? impostaPausa() : null}
            {errore === true ? 
            <Alert variant="danger">
                Errore nell'elaborazione del video 
                {props.pagina === "paziente" ? null : <div><br/><b>Nota</b> il caricamento del video puo' richiedere diversi minuti,riprovare piu' tradi</div>}
            </Alert>
            :
            <div>
                {props.sezione==="paziente" ? checkTimer() : null}
                <div className={ready ? null : "hide" }>
                    <VimeoPlayer url={props.url} playing={playing} setReady={()=>Ready()} restart={restart} restartDone={()=>setRestart(false)} ripeti={ripeti} ripetizioneDone={()=>setRipeti(false)} setErrore={()=>setErrore(true)} />
                    <br/>
                    {props.sezione==="paziente" && props.finito === true ? null :
                    <VimeoController ready={ready} playing={playing} funzione={()=>cambiaStato()} restart={()=>setRestart(true)} ripeti={()=>setRipeti(true)} />
                    }
                </div>
                <div className={!ready ? "loader" : "hide" }>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    <br/>
                    <h3>Caricamento video in corso...</h3>
                </div>
            </div>
            }
        </div>
    )
}