import React,{useState,useContext} from 'react'
import {Alert} from 'react-bootstrap'
import Loader from '../../../../../Loader'
import {PazientiContext} from '../../../../../context/PazientiContext'

export default function Gestore(props){

    const [loading,setLoading] = useState(false);
    const [apiChiamata,setApiChiamata] = useState(false);
    const [errore,setErrore] = useState(false);

    const { getTrainingPaziente } = useContext(PazientiContext);

    const doChiamata = () =>{
        setApiChiamata(true)
        doGetTrainingPaziente()
    }

    const doGetTrainingPaziente = async () =>{
        try{
            await getTrainingPaziente(props.paziente).then(function(){
                successoChiamata()
            })
        }catch(error){
            erroreChiamata(error)
        }
    }

    const successoChiamata = () =>{
        setLoading(false);
        setErrore(false);
        props.fine();
    }

    const erroreChiamata = (error) =>{
        setErrore(true);
        console.log(error);
        setLoading(false);
    }

    return (
        errore ? 
            <Alert variant="danger">Errore durante il tentativo di comunicazione</Alert>
            :
            loading ? 
                <Loader/>
                :
                apiChiamata ? null : doChiamata()

    )
}