import React,{useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Alert,Table } from 'react-bootstrap'
import {PazientiConsumer} from '../../../../../context/PazientiContext'
import PaginationFunction from '../../Tabelle/Pagination';

export default function TabellaTraining(props){

    const recordPerPagina = 5;
    const [pagina,setPagina] = useState(1);

    const stileCella = {width:"20%"}

    const componiTabella = () =>{
        const DATI_TRAINING = PazientiConsumer._currentValue.trainingPaziente;
        const recordTotali = DATI_TRAINING.length;
        const header = componiHeader()
        const body = []
        var contaRecord = 0;
        

        DATI_TRAINING.forEach(training=>{
            contaRecord++
            if((recordPerPagina*pagina)-recordPerPagina+1<=contaRecord && contaRecord<=(recordPerPagina*pagina)){
                var rowToPush = componiRow(training);
                body.push(rowToPush);
            }
        })
        

        return (
            <div>
                <br/>
                {recordTotali > 0 ?
                    <div>
                        <Table striped hover bordered> 
                            <thead>
                                {header}
                            </thead>
                            <tbody>
                                {body}
                            </tbody>
                        </Table>
                        <br/>
                        <PaginationFunction pagina={pagina} setPagina={(p)=>setPagina(p)} recordPerPagina={recordPerPagina} recordTotali={recordTotali}/>
                    </div>
                    :
                    <Alert variant="danger">Nessuna sessione di training registrata per il paziente selezionato</Alert>
                }
            </div>)
    }

    const componiHeader = () =>{
        const row = [];
        const id = "header"
        row.push(<th key={id+"data"}  style={stileCella}>DATA</th>);
        row.push(<th key={id+"inizio"} style={stileCella}>ORA INIZIO</th>);
        row.push(<th key={id+"fine"} style={stileCella}>ORA FINE</th>);
        row.push(<th key={id+"durata"} style={stileCella}>DURATA</th>);
        row.push(<th key={id+"info"} style={stileCella}>INFO PROTOCOLLO</th>);

        return <tr key={id}>{row}</tr>
    }

    const componiRow = (parametri) =>{
        const row = []
        const idTraining = parametri.id;
        const idProtocollo = parametri.idProtocollo;
        const dataTraining = getGiorno(parametri.oraInizio);
        const oraInizio = getOra(parametri.oraInizio);
        const oraFine = getOra(parametri.oraFine);
        const durata = getDurata(parametri.oraInizio,parametri.oraFine);

        row.push(<td key={idTraining+"data"} style={stileCella}>{dataTraining}</td>)
        row.push(<td key={idTraining+"inizio"} style={stileCella}>{oraInizio}</td>)
        row.push(<td key={idTraining+"fine"} style={stileCella}>{oraFine}</td>)
        row.push(<td key={idTraining+"durata"} style={stileCella}>{durata}</td>)
        row.push(<td key={idTraining+"info"} style={stileCella} role="button" onClick={()=>props.estraiProtocollo(idProtocollo)}><FontAwesomeIcon icon={faInfoCircle}/></td>)

        return <tr key={idTraining+"info"}>{row}</tr>
    }

    const getDurata = (inizio,fine) =>{
        const secondiInizio = (new Date(inizio).getTime() / 1000).toFixed(0);
        const secondiFine = (new Date(fine).getTime() / 1000).toFixed(0);
        const durata = secondiFine - secondiInizio

        const secondi = durata % 60 
        const minuti = (durata - secondi) / 60
    
        return minuti + " minuti e " + secondi + " secondi" 
    }

    const getOra = (dataIn) =>{
        const data = new Date(dataIn);
        const ore = data.getHours();
        const minuti = data.getMinutes();
        return ore + ":" + minuti
    }

    const getGiorno = (dataIn) =>{
        const data = new Date(dataIn)
        const giorno = data.getDate();
        const mese = data.getMonth() + 1;
        const anno = data.getFullYear();
        return giorno + "/"+mese+"/"+anno
    }

    return (
    <div>
        {PazientiConsumer._currentValue.trainingPaziente.length === 0 ?
            <div>
                <br/>
                <Alert variant="danger">Non e' stanta ancora registrata nessuna sessione di training</Alert>
            </div>
            :
            componiTabella()}


    </div>)
}