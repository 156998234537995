import React,{useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo,faClipboardList} from '@fortawesome/free-solid-svg-icons';
import './Style.css'
//import component
import Video from '../../components/SetUpVideo/Video'
import AlertEsercizio from './AlertEsercizio'

var triggered = null
export default function Attivita(props){
//Variabili state 
const [video,setVideo]=useState(0)
const [posizioneInCorso,setPosizioneInCorso]=useState(0)
const [inizializza,setInizializza] = useState(true)
const [finito,setFinito] = useState(false);
const [messaggioMostrato,setMessaggioMostrato] = useState(false)
const [annullaTimer,setAnnullaTimer] = useState(false);
const [elencoEsercizi,setElencoEsercizi] = useState(null);
const [fermaEsecuzione,setFermaEsecuzione] = useState(true);

const nextVideo = (esercizio) =>{
    setAnnullaTimer(true);
    
    const ultimoIndex = props.protocollo.esercizi.length - 1
    const ultimoEsercizio = elencoEsercizi[ultimoIndex]
    if(ultimoEsercizio !== esercizio ){
            if(esercizio === triggered){
                var next = false;
                elencoEsercizi.forEach((es)=>{
                    if(next) {
                        setVideo(es.url);
                        setPosizioneInCorso(es.posizione);
                        triggered=es;
                        next=false
                    }else{
                        if(esercizio.posizione===es.posizione) next=true
                    }
                })
            }
    }else{
        setFinito(true);
        setFermaEsecuzione(true);
        if(!messaggioMostrato && !props.finito) {
            alert("Training ultimato.\nPremere 'FINE TRAINING' per ultimare la sessione di training.\nPremere 'RICOMINCIA TRAINING' per ripetere il training");
            setMessaggioMostrato(true);
        }
    }

}

const assemblaTraining=()=>{
    const elencoEsercizi = [];
    const elencoEserciziObj = [];
    var cont=0;
    props.protocollo.esercizi.forEach((esercizio)=>{
        var nuovoEs = {...esercizio}
        nuovoEs.posizione = cont;
        elencoEserciziObj.push(nuovoEs);
        elencoEsercizi.push(
        <AlertEsercizio key={cont} posizione={cont} posizioneInCorso={posizioneInCorso} esercizio={esercizio} />)
        cont++
    })
    if(inizializza===true){
        setFermaEsecuzione(true)
        const es = elencoEserciziObj[0];
        triggered=es;
        setVideo(es.url);
        setInizializza(false);
        setPosizioneInCorso(0);
        setElencoEsercizi(elencoEserciziObj);
    }

    return elencoEsercizi
}//assembla training

const ricominciaTraining=()=>{
    setAnnullaTimer(true);
    setPosizioneInCorso(0);
    setFinito(false);
    setMessaggioMostrato(false);
    const es = elencoEsercizi[0];
    triggered=es;
    setVideo(es.url);
}

const fineTraining = () =>{
    setAnnullaTimer(true);
    triggered=null
    props.fine();
}

    return(
    <>
        <Row>
            <Col md="5">
                <div style={{textAlign:"center",color:"#00acc1"}}>
                    <h1><FontAwesomeIcon icon={faClipboardList} /></h1>
                </div>
                <br/>
                <h3>{assemblaTraining()}</h3>
            </Col>
            <Col>
                <h1 style={{textAlign:"center",color:"#00acc1"}}>
                    <FontAwesomeIcon icon={faVideo}/>
                </h1>
                <br/>
                <Video {...props} annullaTimer={annullaTimer} triggered={triggered} fermaEsecuzione={fermaEsecuzione} trainingFermato={()=>setFermaEsecuzione(false)}
                setAnnullaTimer={()=>setAnnullaTimer(false)} sezione="paziente"  fineTraining={()=>fineTraining()}
                finito={finito} posizioneInCorso={posizioneInCorso} length={props.protocollo.esercizi.length} 
                esercizio={elencoEsercizi !== null ? elencoEsercizi[posizioneInCorso] : props.protocollo.esercizi.length} video={video} nextVideo={(esercizio)=>nextVideo(esercizio)} pagina="paziente" ricomincia={()=>ricominciaTraining()}/>
                <br/><br/>
         </Col>
        </Row>
    </>
    );
}