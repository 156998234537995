import React, { Component } from 'react';


import api from '../api';

const ProtocolliContext = React.createContext();

const INITIAL_STATE = { elencoProtocolli : null , ultimoSave : null};

class ProtocolliProvider extends Component {
  state = INITIAL_STATE;


  getAllProtocolli = async () =>{
    const { data } = await api.getProtocolli();
    var protocolli = data.nameValuePairs.protocolli
    this.setState({ elencoProtocolli : protocolli })
  }

  saveProtocollo = async (protocollo) =>{
    this.setState({ultimoSave:protocollo})
    await api.saveProtocollo(protocollo)
  }

  render() {
    return (
      <ProtocolliContext.Provider
        value={{...this.state , getAllProtocolli:this.getAllProtocolli , saveProtocollo:this.saveProtocollo }}
      >
        {this.props.children}
      </ProtocolliContext.Provider>
    )
  }
}
const ProtocolliConsumer = ProtocolliContext.Consumer;

export { ProtocolliProvider , ProtocolliConsumer , ProtocolliContext }