import React,{useState,useContext} from 'react'
import {PazientiConsumer,PazientiContext} from '../../../../context/PazientiContext'
import {EserciziContext} from '../../../../context/EserciziContext'
import {PatologieContext} from '../../../../context/PatologieContext'
import {ProtocolliContext} from '../../../../context/ProtocolliContext'
import {Alert} from 'react-bootstrap'
import Loader from '../../../../Loader'

export default function Gestore(props){

    const { getAllPazienti , getUltimoProtocollo , assegnaProtocollo } = useContext(PazientiContext);
    const { getAllEsercizi , elencoEsercizi } = useContext(EserciziContext);
    const { getAllPatologie } = useContext(PatologieContext);
    const { getAllProtocolli , elencoProtocolli} = useContext(ProtocolliContext);

    const[loading,setLoading] = useState(false);
    const[apiChiamata,setApiChiamata] = useState(false);
    const[errore,setErrore] = useState(false);
    const[salvataggioEseguito,setSalvataggioEseguito] = useState(false);

    const doChiamata = () =>{
        setLoading(true)
        if(props.tipo==="getter") doGetPazienti()
        if(props.tipo==="ultimoProtocollo") doGetUltimoProtocollo()
        if(props.tipo==="assegnaProtocollo") doAssegnaProtocollo()
    }

    const doGetPazienti = async () =>{
        try{
            await getAllPazienti().then(function(){
                doGetEsercizi();
            })
        }catch(error){
            erroreChiamata(error);
        }
    }

    const doGetUltimoProtocollo = async () =>{
        try{
            await getUltimoProtocollo(props.idprotocollo,props.idpaziente).then(async function(){
                doGetEsercizi()
            })
        }catch(error){
            erroreChiamata(error);
        }
    }

    const doGetEsercizi = async () =>{
        if(elencoEsercizi===null){
            try{
                await getAllEsercizi().then(async function(){
                    doGetPatologie()
                })
            }catch(error){
                erroreChiamata(error)
            }
        }else{
            successoChiamata()
        }
    }

    const doGetPatologie = async () =>{
        try{
            await getAllPatologie().then(async function(){
                doGetAllProtocolli()
            })
        }catch(error){
            erroreChiamata(error)
        }
    }

    const doGetAllProtocolli = async () =>{
        if(elencoProtocolli===null){
            try{
                await getAllProtocolli().then(function(){
                    successoChiamata()
                })
            }catch(error){
                erroreChiamata(error)
            }
        }else{
            successoChiamata()
        }
    }

    const doAssegnaProtocollo = async () =>{
        var assegnazione = {paziente:props.idpaziente , protocollo:props.idprotocollo , giorni:props.giorni , settimane : props.settimane}
        if(assegnazione !== PazientiConsumer._currentValue.assegnazioneTriggered){
            try{
                await assegnaProtocollo(assegnazione).then(async function(){
                    doGetPazienti()
                })
            }catch(error){
                erroreChiamata(error);
            }
        }else{
            successoChiamata()
        }
    }

    const successoChiamata = () =>{
        if(props.tipo==="getter") props.fine()
        if(props.tipo==="ultimoProtocollo") props.fine()
        if(props.tipo==="assegnaProtocollo") props.fine()
        setLoading(false)
        setApiChiamata(true)
        setErrore(false)
        setSalvataggioEseguito(true)
    }

    const erroreChiamata = (error) =>{
        console.error(error);
        setLoading(false)
        setApiChiamata(true)
        setErrore(true)
    }

    return(<>
        {salvataggioEseguito ?
            <div>
                <Alert variant="success" >salvataggio eseguito con successo</Alert>
            </div>
            :
            loading===true ? 
                <Loader sezione="m"/>
                :
                errore===true ? <><br/><Alert variant="danger">Errore di comunicazione</Alert></>
                    :
                    apiChiamata===true ? null 
                        : 
                        doChiamata()
            } 
    </>   
    )
}