import React,{useState} from 'react'
import Modal from 'react-modal'
import {Button,Table,Row,Col,Form,Alert} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle,faTimesCircle } from '@fortawesome/free-solid-svg-icons';
//components
import InfoEsercizio from '../ModalComponent/InfoEsercizio'
import GestioneEserciziVideo from './GestioneEserciziVideo'
import UtilInfoProtocollo from '../Utill/UtilInfoProtocollo'
import UtilEsercizio from '../Utill/UtilEsercizi'
import CreazioneProtocollo from '../SelezioneMenu/CreazioneProtocolli'
//context
import {PatologieConsumer} from '../../../../context/PatologieContext'
import {EserciziConsumer} from '../../../../context/EserciziContext'

import './Style.css'

Modal.setAppElement("#root");
export default function InfoProtocollo(props){
    const [modalIsOpen,setModalIsOpen]=useState(true)
    const [vediEsercizio,setVediEsercizio]=useState(0)
    const [durataImpostata,setDurataImpostata]=useState(false)
    const [durata,setDurata]=useState(0);
    const [modificaProtocollo,setModificaProtocollo] = useState(false)

    const eserciziPreview=[]

    const stileButton = {marginLeft:"80%",width:"15%",backgroundColor:"#003769",color:"white",position:"absolute"}

    const getEsercizi = () =>{
        var durataTotale=0
        const ESERCIZI = EserciziConsumer._currentValue.elencoEsercizi;
        const ESERCIZI_ASSEGNATI = props.protocollo.eserciziAssegnati;
        const SPECIALITA = PatologieConsumer._currentValue.elencoPatologie;
        const tabella=[]
        const ripetizioni=[]
        //header
        tabella.push(UtilInfoProtocollo.getHeaderTabella());
        //body
        var elencoEserciziProtocollo=[]
        ESERCIZI_ASSEGNATI.forEach(assegnazione=>{
            ESERCIZI.forEach(esercizio=>{
                if(esercizio.ID === assegnazione.idEsercizio) {
                    elencoEserciziProtocollo.push(esercizio)
                    ripetizioni.push(assegnazione.ripetizioni)
                    durataTotale = durataTotale + (assegnazione.ripetizioni * esercizio.durata);
                    eserciziPreview.push(esercizio);
                }
            })
        })
        const rowsBody=[]
        
        var contatore=0
        elencoEserciziProtocollo.forEach((es)=>{
            const rowBody=[]
            rowBody.push(<td key={"Titolo"+es.ID}>{es.titolo}</td>)
            rowBody.push(<td key={"Patologia"+es.ID}>{UtilEsercizio.getMessaggioPatologie(SPECIALITA,es.patologie)}</td>)
            rowBody.push(<td key={"Difficolta"+es.ID}>{es.livello}</td>)
            rowBody.push(<td key={"Fase"+es.ID}>{es.faseTraining}</td>)
            rowBody.push(<td key={"Ripetizioni"+es.ID}>{ripetizioni[contatore]}</td>)
            rowBody.push(<td key={"Info"+es.ID} role="button" onClick={()=>setVediEsercizio(es)}><FontAwesomeIcon icon={faInfoCircle}/></td>)

            rowsBody.push(<tr key={"Esercizio"+es.ID}>{rowBody}</tr>)
            contatore++
        });
        tabella.push(<tbody key="BodyTabellaEsercizi">{rowsBody}</tbody>)
        if(durataImpostata===false) {   
            setDurata(durataTotale)
            setDurataImpostata(true)
        }
       

        return <Table striped bordered hover >{tabella}</Table>

    }//getEsercizi

    const getPatologie = () =>{
        const PATOLOGIE_PROTOCOLLO = props.protocollo.patologie
        const SPECIALITA = PatologieConsumer._currentValue.elencoPatologie;
        
        return UtilInfoProtocollo.getPatologieText(PATOLOGIE_PROTOCOLLO,SPECIALITA)
        
    }//getPatologie

    const cambiaProtocolloAssegnato = () =>{
        props.assegnaProtocollo()
        props.funzione()
    }

    const getDurata = () =>{
        var minuti,messMinuti
        var secondi
        secondi = durata%60
        minuti = (durata-secondi)/60
        if(minuti===1) messMinuti=minuti+" minuto"
        else messMinuti=minuti+" minuti"
        var messaggio=""
        if(minuti>0) messaggio=messMinuti+" e "+secondi+" secondi"
        else messaggio=secondi+" secondi"
        return messaggio
    }
return(
<Modal 
    isOpen={modalIsOpen} 
    onRequestClose={()=>setModalIsOpen(false)} 
    shouldCloseOnOverlayClick={false} >
     {vediEsercizio===0?
        <div>
            <h2 style={{marginLeft:"95%"}}>
                <FontAwesomeIcon icon={faTimesCircle} className="exit" role="button" onClick={()=>props.funzione()}/>
            </h2>
            {modificaProtocollo === true ? 
            <CreazioneProtocollo {...props}  tipo="modifica"/>
                :
                <div>
                    <Row>
                        <Col md="6">
                        <h4>
                            <br/>
                            <Row>
                                <Col>Titolo</Col>
                                <Col><Form.Control type="text" readOnly="readonly" className="FormControlRead" value={props.protocollo.titolo}/></Col> 
                            </Row>
                            <br/>
                            <Row>
                                <Col>Descrizione</Col>
                                <Col><Form.Control as="textarea" readOnly="readOnly" className="FormControlRead" rows="4" value={props.protocollo.descrizione} /></Col> 
                            </Row>
                            <br/>
                            <Row>
                                <Col>Difficoltà</Col>
                                <Col><Form.Control type="text" readOnly="readonly" className="FormControlRead" value={props.protocollo.livello}/></Col>
                            </Row> 
                            <br/>
                            <Row>
                                <Col>Durata (settimane)</Col>
                                <Col><Form.Control type="text" readOnly="readonly" className="FormControlRead" value={props.protocollo.numeroSettimane}/></Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>Frequenza settimanale</Col>
                                <Col><Form.Control type="text" readOnly="readonly" className="FormControlRead" value={props.protocollo.frequenza+" giorni"}/></Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>Durata totale training</Col>
                                <Col><Form.Control type="text" readOnly="readonly" className="FormControlRead" value={getDurata()} /></Col>
                            </Row>
                            <br/><hr/>
                            Patologie associate
                            <br/><br/>
                            {getPatologie()}
                        </h4>
                        </Col>
                        <Col md="6">
                        {props.distinzione!=="InfoPaziente" ?
                        <> 
                            <br/>
                            <Button style={{backgroundColor:"#003769",color:"white",width:"100%"}} onClick={()=>setModificaProtocollo(true)}>MODIFICA PROTOCOLLO</Button>
                        </>:null}
                        <h4>
                            <br/>
                            Elenco esercizi
                        </h4>
                        <div>
                            {getEsercizi()}
                        </div>
                        </Col>
                    </Row>           
                    <br/>
                    <Alert style={{backgroundColor:"#003769",color:"white"}}><b>ANTEPRIMA DI VISUALIZZAZIONE</b></Alert>
                    <br/>
                    {durataImpostata ? <GestioneEserciziVideo esercizi={eserciziPreview} primoEsercizio={eserciziPreview[0]} primoVideo={eserciziPreview[0].urlVideo}/> : null}
                    {props.distinzione==="InfoPaziente" && props.protocollo.ID !== props.ultimoProtocollo ? <><br/>
                    <Button onClick={()=>cambiaProtocolloAssegnato()} style={stileButton}>SELEZIONA</Button> <br/><br/></>:null}
                    <Button  onClick={()=>props.funzione()} style={stileButton}>INDIETRO</Button>
                </div>
            }
        </div>:<InfoEsercizio dist={props.distinzione} esercizio={vediEsercizio} funzione={()=>setVediEsercizio(0)}/>
        }

</Modal>
)
}