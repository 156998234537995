import React,{useState} from 'react'
import {PazientiConsumer} from '../../../../../context/PazientiContext'
import Gestore from '../../GestionePazientiApi/Gestore'
import InfoUltimoProtocollo from './InfoUltimoProtocollo'
import {Alert} from 'react-bootstrap'

export default function SelectComponent(props){

    const[ID_PROTOCOLLO,setId] =useState(props.paziente.protocollo)
    const[ID_PAZIENTE,setIdPaziente] = useState(props.paziente.id)
    const NOMINATIVO = props.paziente.cognome+" "+props.paziente.nome 
    const[chiamataFatta,setChiamataFatta]=useState(false)

    return (<>
    {
        ID_PROTOCOLLO === 0 ? <div>
            <br/><br/>
            <Alert variant="danger">Non e' stato ancora assegnato nessun protocollo al paziente {NOMINATIVO}</Alert>
            </div>
        :
            !chiamataFatta ? <Gestore tipo="ultimoProtocollo" idpaziente={ID_PAZIENTE} idprotocollo={ID_PROTOCOLLO} fine={()=>setChiamataFatta(true)} />
            : !chiamataFatta ? <Gestore idprotocollo={ID_PROTOCOLLO} fine={()=>setChiamataFatta(true)}/>
                : <InfoUltimoProtocollo {...props} protocollo={PazientiConsumer._currentValue.ultimoProtocollo} />
    }
    </>)

}