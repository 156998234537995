import React,{useState} from 'react'
import {Table,Row,Col,Form,Alert} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle , faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Pagination from './Pagination'
//import json dati
import {ProtocolliConsumer} from '../../../../context/ProtocolliContext'
import {PatologieConsumer} from '../../../../context/PatologieContext'
import Util from '../Utill/UtilProtocolli'

export default function TabellaProtocolli(props){

    const messaggioFrequenzaSettimanale = props.sezione === "assegnazione" ? "Frequenza Settimanale Consigliata " : "Frequenza Settimanale"
    const messaggioSettimane = props.sezione === "assegnazione" ? "Numero Settimane Consigliato " : "Numero Settimane"

    const [titolo,setTitolo]=useState("")
    const [patologia,setPatologia]=useState("")
    const [numeroSettimane,setNumeroSettimane]=useState("")
    const [pagina,setPagina]=useState(1)
    const recordPerPagina = 4
    var contaRecord = 0
    const filtroSettimane = (event) =>{
        var valore = event.target.value
        var parser=parseInt(valore)
        if(valore==="") {
            setNumeroSettimane("")
        }
        else {
            if(parser <= 0 || parser>50) alert("Errore:"+valore+" valore non valido\nRange valido 1->50")
            else setNumeroSettimane(valore)
        } 
    }

    const creaTabella = () =>{
        const PROTOCOLLI = ProtocolliConsumer._currentValue.elencoProtocolli
        const PATOLOGIE = PatologieConsumer._currentValue.elencoPatologie
        contaRecord=0
        const tabella=[]
        const header=[],headerRow=[],rows=[]
        //componimento header
        headerRow.push(<th key="titolo">Titolo</th>)
        headerRow.push(<th key="patologia">Patologia</th>)
        headerRow.push(<th key="FreqSettimanale">{messaggioFrequenzaSettimanale}</th>)
        headerRow.push(<th key="NumeroSettimane">{messaggioSettimane}</th>)
        headerRow.push(<th key="Info">Info</th>)
        if(props.sezione === "assegnazione"){
            headerRow.push(<th key="Seleziona">Seleziona</th>)
        }
        header.push(<tr key="headerRow">{headerRow}</tr>)
        tabella.push(<thead key="HeaderProtocolli">{header}</thead>)
    //componimento body
    PROTOCOLLI.forEach((protocollo)=>{
        var checkFiltri = Util.checkFiltri(protocollo,PATOLOGIE,titolo,numeroSettimane,patologia);
        if(checkFiltri){
            contaRecord++
            if((recordPerPagina*pagina)-recordPerPagina+1<=contaRecord && contaRecord<=(recordPerPagina*pagina)){    
                const row=[]
                row.push(<td key={"Titolo"+protocollo.ID}>{protocollo.titolo}</td>)
                row.push(<td key={"Patologia"+protocollo.ID}>{Util.getMessaggioPatologie(protocollo,PATOLOGIE)}</td>)
                row.push(<td key={"Frequenza"+protocollo.ID}>{protocollo.frequenza>1?<>{protocollo.frequenza+" "}giorni</>:<>{protocollo.frequenza+" "}giorno</>}</td>)
                row.push(<td key={"NumeroSettimane"+protocollo.ID}>{protocollo.numeroSettimane}</td>)
                row.push(<td key={"InfoProtocollo"+protocollo.ID} role="button" onClick={()=>props.setInfoProtocollo(protocollo)}><FontAwesomeIcon icon={faInfoCircle}/></td>)
                if(props.sezione === "assegnazione"){
                    row.push(<td key={"SelezioneProtocollo"+protocollo.ID} role="button" onClick={()=>props.assegna(protocollo)}><FontAwesomeIcon icon={faPlusCircle}/></td>)
                }
                rows.push(<tr key={"Protocollo"+protocollo.ID}>{row}</tr>)
            }
        }     
    })

    tabella.push(<tbody key={"BodyProtocolli"}>{rows}</tbody>)

    return (
    <>
    {   contaRecord > 0 ?
        <div>
        <Table striped bordered hover>{tabella}</Table>
        <Pagination recordTotali={contaRecord} recordPerPagina={recordPerPagina} setPagina={(pag)=>setPagina(pag)}/>
        </div>
        :
        <Alert variant="danger">Nessuna corrispondenza trovata con i parametri selezionati</Alert>
    }
    </>
    )}
    return(<div>
        <Row>
            <Col>
                <Form.Control placeholder="Titolo" onChange={(event)=>setTitolo(event.target.value)} value={titolo}/>
            </Col>
            <Col>
                <Form.Control placeholder="Patologia" onChange={(event)=>setPatologia(event.target.value)} value={patologia}/>
            </Col>
            <Col>
                <Form.Control type="number" placeholder="Numero Settimane" onChange={(event)=>filtroSettimane(event)} value={numeroSettimane}/>
            </Col>
        </Row>
        <br/>
        {creaTabella()}
    </div>)
}