import React from 'react'
import {Form,Col,Row,Alert} from 'react-bootstrap'

export default function FormLabel(props){
    return(
        <Row>
            <Col>
                <Alert style={{backgroundColor:"lightgrey"}} className="AlertFormLabel">
                    {props.testo}
                </Alert>
            </Col>
            <Col>
                <Form.Control type="text" placeholder={"inserisci "+props.testo.toLowerCase()} onChange={(event)=>props.funzione(event.target.value)} value={props.valore}/>
            </Col>
        </Row>
    )
}