import React from 'react'
import { Row,Col,Form} from 'react-bootstrap'

export default function VisualizzaDato(props){

    return (
        
        props.tipo!==1?
        <div>
            <Row>
                <Col md="3">
                    {props.nome}
                </Col>
                <Col md="4">
                    <Form.Control  type="text" readOnly="readonly" className="FormControlRead" defaultValue={props.valore}/>
                </Col>
            </Row>
            <br/>
        </div>
        :
        <Form.Group as={Row}>
            <Form.Label column md="5">
            {props.nome}
            </Form.Label>
            <Col md="7">
                <Form.Control readOnly defaultValue={props.valore} />
            </Col>
        </Form.Group>
    )
}