import React,{useState} from 'react'
import {Row,Col} from 'react-bootstrap'
//import components
import AlertEsercizio from '../../../../components/BasicComponents/AlertEsercizio'
import Video from '../../../../components/SetUpVideo/Video'

export default function GestioneEserciziVideo(props){
    var elencoEsercizi = props.esercizi
    const [esercizioInCorso,setEsercizioInCorso]=useState(props.primoEsercizio)
    const [posizioneInCorso,setPosizioneInCorso]=useState(0)
    const [video,setVideo]=useState(props.primoVideo)
    const cambioEsercizi = []
    var primoEsercizio=null
    const getAlerts = () =>{
    var body=[]
    var cont=0
        elencoEsercizi.forEach(esercizio =>{
            cambioEsercizi.push(esercizio)
            body.push(<AlertEsercizio key={cont+"Alert"} primoEsercizio={cont===0?true:false} 
            esercizioInCorso={esercizioInCorso} posizioneInCorso={posizioneInCorso} esercizio={esercizio} posizione={cont}/>)
            if(primoEsercizio===null) primoEsercizio=esercizio
            cont++
        });


        return (
        <Row>
            <Col md="4">
            {props.pagina==="pazienti"?<h3>{body}</h3>:<>{body}</>}
            </Col>
            <Col>
            <Video video={video} esercizio={esercizioInCorso} posizioneInCorso={posizioneInCorso}
                nextVideo={()=>nextVideo()}
                prevVideo={()=>prevVideo()}
                pagina="GestioneEserciziVideo"
                />
            </Col>
        </Row>
    )}
    const nextVideo = (esercizio) =>{
        var cont=0
        cambioEsercizi.forEach((esercizio)=>{
            if(cont===posizioneInCorso+1) {
                setEsercizioInCorso(esercizio)
                setPosizioneInCorso(cont)
                setVideo(esercizio.urlVideo)
            }
            cont++
        })
    }
    const prevVideo = (esercizio) =>{
        var cont=0
        cambioEsercizi.forEach((esercizio)=>{
            if(cont===posizioneInCorso-1) {
                setEsercizioInCorso(esercizio)
                setPosizioneInCorso(cont)
                setVideo(esercizio.urlVideo)
            }
            cont++
        })
    }
    
    return(<h5>{getAlerts()}</h5>)
}