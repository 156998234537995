import React, { Component } from 'react';

import api from '../api';

const EserciziContext = React.createContext();

const INITIAL_STATE = { elencoEsercizi : null , elencoSedi : null , ultimoSave:null };

class EserciziProvider extends Component {
  state = INITIAL_STATE;


    getAllEsercizi = async () =>{
      const { data } = await api.getEsercizi();
      var esercizi = data.nameValuePairs.esercizi
      var sedi = data.nameValuePairs.sedi
      
      this.setState({ elencoEsercizi : esercizi , elencoSedi : sedi })
    }

    saveEsercizio = async (esercizio) =>{
      this.setState({ultimoSave:esercizio})
      await api.saveEsercizio(esercizio)
    }


  render() {
    return (
      <EserciziContext.Provider
        value={{...this.state , getAllEsercizi:this.getAllEsercizi , saveEsercizio:this.saveEsercizio }}
      >
        {this.props.children}
      </EserciziContext.Provider>
    )
  }
}
const EserciziConsumer = EserciziContext.Consumer;

export { EserciziProvider , EserciziConsumer , EserciziContext }