import React from 'react';
import Navigation from './Navigation'
import {PatologieProvider} from './context/PatologieContext'
import {EserciziProvider} from './context/EserciziContext'
import {ProtocolliProvider} from './context/ProtocolliContext'
import {PazientiProvider} from './context/PazientiContext'
import {PazienteProvider} from './context/PazienteContext'

function App() {
  return (
    <ProtocolliProvider>
      <PatologieProvider>
        <EserciziProvider>
          <PazientiProvider>
            <PazienteProvider>
              <Navigation/>
            </PazienteProvider>
          </PazientiProvider>
        </EserciziProvider>
      </PatologieProvider>
    </ProtocolliProvider>
  );
}

export default App;
