import React,{useState} from 'react'
import Modal from 'react-modal'
import {Button,Table,Badge,Row,Col,Form} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './Style.css'
//import components
import Util from '../Utill/UtilEsercizi'
import Video from '../../../../components/SetUpVideo/Video'
import ModificaEsercizio from '../Modifica/ModificaEsercizio'
//import context
import {EserciziConsumer} from '../../../../context/EserciziContext'
import {PatologieConsumer} from '../../../../context/PatologieContext'

Modal.setAppElement("#root");

export default function InfoEsercizio(props){
    const [modalIsOpen,setModalIsOpen]=useState(true);

    const [modificaEsercizio,setModificaEsercizio]=useState(false);

    const Tabella=[]
    const componiTabella = () =>{
        const SEDI = EserciziConsumer._currentValue.elencoSedi;

        const rowHead=[]
        rowHead.push(<th key="HeaderSede">Sede</th>)
        rowHead.push(<th key="HeaderInteressata">Interessata</th>)
        Tabella.push(<thead key="Header"><tr key="HeaderRow">{rowHead}</tr></thead>)
        
        const rowsBody = Util.getSediEsercizio(props.esercizio,SEDI);
        Tabella.push(<tbody key="body">{rowsBody}</tbody>)

        return(<Table striped bordered hoover style={{width:"50%"}}>{Tabella}</Table>)
    }

    const getPatologie = () =>{
        const PATOLOGIE_ESERCIZIO = props.esercizio.patologie;
        const SPECIALITA = PatologieConsumer._currentValue.elencoPatologie;

        return Util.getPatologieText(PATOLOGIE_ESERCIZIO,SPECIALITA)
    }

    const add = () =>{
            props.aggiungiEsercizio(props.esercizio)
            alert("L'esercizio '"+props.esercizio.titolo+"' è stato aggiunto al protocollo")
            onClose()
    }

    const onClose = () =>{
        setModalIsOpen(false)
        props.funzione()
    }

    const getDurata = () =>{
        var minuti,messMinuti
        var secondi
        secondi = props.esercizio.durata%60
        minuti = (props.esercizio.durata-secondi)/60
        if(minuti===1) messMinuti=minuti+" minuto"
        else messMinuti=minuti+" minuti"
        var messaggio=""
        if(minuti>0) messaggio=messMinuti+" e "+secondi+" secondi"
        else messaggio=secondi+" secondi"
        return messaggio
    }
return(
<Modal 
    isOpen={modalIsOpen} 
    onRequestClose={()=>setModalIsOpen(false)} 
    shouldCloseOnOverlayClick={false} >
   <h2 style={{marginLeft:"95%"}}>
        <FontAwesomeIcon icon={faTimesCircle} className="exit" role="button" onClick={()=>onClose()}/>
    </h2>
    <br/>
    {props.dist !== "InfoPaziente" && modificaEsercizio ? <ModificaEsercizio {...props} annulla={()=>setModificaEsercizio(false)} /> 
    : 
   <div>
       <div style={{textAlign:"center"}}>
            <Badge style={{backgroundColor:"#003769",color:"white"}}>
                <h2>
                {props.esercizio.titolo}
                </h2>
            </Badge>
       </div>
       <Row>
            <Col style={{marginTop:"50px"}}>
                <h3>
                    <Row>
                        <Col>Descrizione</Col>
                        <Col><Form.Control as="textarea" readOnly="readOnly" className="FormControlRead" rows="4" defaultValue={props.esercizio.descrizione}/></Col>
                    </Row><br/>
                    <Row>
                        <Col>Durata</Col>
                        <Col><Form.Control type="text" readOnly="readonly" className="FormControlRead" defaultValue={getDurata()}/></Col>
                    </Row><br/>
                    <Row>
                        <Col>Fase di training</Col>
                        <Col><Form.Control type="text" readOnly="readonly" className="FormControlRead" defaultValue={props.esercizio.faseTraining}/></Col>
                    </Row><br/>
                    <Row>
                        <Col>Livello di difficoltà</Col>
                        <Col><Form.Control type="text" readOnly="readonly" className="FormControlRead" defaultValue={props.esercizio.livello}/></Col>
                    </Row><br/>
                    <Row>
                        <Col>Istruzioni</Col>
                        <Col><Form.Control as="textarea" readOnly="readOnly" className="FormControlRead" rows="4" defaultValue={props.esercizio.istruzioni}/></Col>
                    </Row>
                    <hr/>
                    Patologie associate
                    <br/><br/>
                    {getPatologie()}
                    <br/>
                    <hr/>
                    Sedi interessate
                    <br/>
                </h3>
                
                {componiTabella()}
            </Col>
            <Col>
                <br/><br/>
                {props.dist === "InfoPaziente" ?
                <></>
                :
                <Button style={{width:"100%",backgroundColor:"#003769"}} onClick={()=>setModificaEsercizio(true)}>MODIFICA ESERCIZIO</Button>
                }
                <br/><br/>
                <Video pagina="InfoEsercizio" video={props.esercizio.urlVideo}/>
            </Col>
       </Row>
    </div> }
    {props.distinzione==="aggiungi"?<> <Button onClick={()=>add()} style={{marginLeft:"80%",width:"15%",backgroundColor:"#003769"}}>AGGIUNGI ESERCIZIO</Button> <br/><br/></>:null}
    {modificaEsercizio===false ? <Button onClick={()=>onClose()} style={{marginLeft:"80%",width:"15%",backgroundColor:"#003769"}}>CHIUDI</Button>:null}
</Modal>
)
}