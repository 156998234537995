import React,{useState,useContext} from 'react'
import {Button,Row,Col,Alert} from 'react-bootstrap'
//Components
import GestorePatologie from '../GestorePatologie/Gestore'
import RecapEsercizio from '../Recap/RecapEsercizio'
//Form Components
import FormLabel from '../FormComponents/FormLabel'
import FormDropdown from '../FormComponents/FormDropdown'
import FormTextarea from '../FormComponents/FormTextarea'
import FormLabelNumber from '../FormComponents/FormLabelNumber'
import FormCheckbox from '../FormComponents/FormCheckbox'
//Constans
import {LivelloDifficolta,FaseTraining} from '../../../StaticComponents/ElenchiOpzioniDropdown'
import {EserciziConsumer} from '../../../../context/EserciziContext'

export default function ModificaEsercizio(props){

    const [titolo,setTitolo] = useState(props.esercizio.titolo);
    const [descrizione,setDescrizione] = useState(props.esercizio.descrizione);
    const [durata,setDurata] = useState(props.esercizio.durata);
    const [faseTraining,setFaseTraining] = useState(props.esercizio.faseTraining);
    const [istruzioni,setIstruzioni] = useState(props.esercizio.istruzioni);
    const [livello,setLivello] = useState(props.esercizio.livello);
    const [objectSave,setObjectSave] = useState(null)

    const [elencoPatologie,setElencoPatologie] = useState(null);
    const [allSedi,setAllSedi] = useState(null)
    const [elencoSedi,setElencoSedi] = useState(null);
    
    const aggiornaPatologie = (nuovePatologie) =>{
        setElencoPatologie(nuovePatologie);
    }

    const aggiornaSedi = (event) =>{
        var sede = event.target.id
        var objectOut = {...elencoSedi}
        objectOut[sede] = !elencoSedi[sede]
        setElencoSedi(objectOut)
    }

    const inizializzaSedi = () =>{
        const SEDI = EserciziConsumer._currentValue.elencoSedi
        const sediDefault = props.esercizio.sediInteressate;
        var objectOut = { }
        SEDI.forEach(sede =>{
            var check = false;
            sediDefault.forEach(def=>{
                if(sede.ID === def.ID) check = true
            })
            objectOut[sede.nome] = check
        })
        setAllSedi(SEDI)
        setElencoSedi(objectOut)
    }

    const invio = () =>{

        var patologieSend = ""
        elencoPatologie.forEach(specialita=>{
            var patologieSpecialita = specialita.patologie;
            patologieSpecialita.forEach(patologia=>{
                if(patologia.checked === true) {
                    if(patologieSend === "") patologieSend=patologia.ID
                    else patologieSend=patologieSend+"$"+patologia.ID
                }
            })
        })

        var sediSend = ""
        allSedi.forEach(sedePossibile=>{
            var checked = elencoSedi[sedePossibile.nome]
            if(checked === true){
                if(sediSend === "") sediSend = sedePossibile.ID
                else sediSend = sediSend+"$"+sedePossibile.ID
            }
        })

        var messaggioErrore = "Errore : \n"
        var errorePresente = false
        if(patologieSend === "" ) {
            messaggioErrore = "- Inserisci almeno una patologia \n"
            errorePresente = true
        }
        if(sediSend === "" ) {
            messaggioErrore = messaggioErrore + "- Inserisci almeno una sede interessata\n"
            errorePresente = true
        }
        if(durata > 3600 || durata < 10 ){
            messaggioErrore = messaggioErrore + "- Durata ("+durata+") non valida , durata minima = 10 -> durata massima = 3600 "
            errorePresente = true
        }

        if(errorePresente === true) alert(messaggioErrore)
        else {
            var saveObject = {
                ID:props.esercizio.ID,
                titolo:titolo,
                descrizione:descrizione,
                durata:durata,
                faseTraining:faseTraining,
                istruzioni:istruzioni,
                livello:livello,
                patologie:patologieSend,
                sedi:sediSend,
                url:props.esercizio.urlVideo
            }
            var recap = {
                saveObject : saveObject,
                ...saveObject,
                sedi:allSedi,
                elencoPatologie:elencoPatologie,
                livelloDifficolta:livello
            }
            setObjectSave(recap)
        }

    }

    return (
        <>
        {objectSave !== null ? 
        <RecapEsercizio esercizio={objectSave} sezione="modifica" indietro={()=>setObjectSave(null)} />
        :
        <div>
            <br/>
            <FormLabel testo="Titolo esercizio" funzione={(valore)=>setTitolo(valore)} valore={titolo}/>
            <hr/>
            <FormTextarea testo="Descrizione esercizio" valore={descrizione} funzione={(valore)=>setDescrizione(valore)}/>
            <br/> 
            <GestorePatologie elencoPatologie={elencoPatologie} aggiornaPatologie={(nuovePatologie)=>aggiornaPatologie(nuovePatologie)} 
            aggiornaSedi={(nuoveSedi)=>aggiornaSedi(nuoveSedi)} sezione={'modifica'} oldPatologie={props.esercizio.patologie}/>
            <br/>
            <FormDropdown testo="Livello di difficoltà" testoDropdown={livello} opzioni={LivelloDifficolta} funzione={(liv)=>setLivello(liv)}/>
            <hr/>
            <FormDropdown testo="Fase di training" testoDropdown={faseTraining} opzioni={FaseTraining} funzione={(fase)=>setFaseTraining(fase)}/>
            <hr/>
            {elencoSedi === null ? inizializzaSedi() 
                :
                <Row>
                    <Col>
                        <Alert  style={{backgroundColor:"lightgrey"}}>Inserisci la/e sede/i interessata/e</Alert>
                    </Col>
                    <Col>
                        <Alert>
                            <FormCheckbox identificativo="Testa" checked={elencoSedi.Testa} funzione={(event)=>aggiornaSedi(event)} testo="Testa" nomeClasse="checkBoxUno"/>
                            <FormCheckbox identificativo="Collo" checked={elencoSedi.Collo} funzione={(event)=>aggiornaSedi(event)} testo="Collo" nomeClasse="checkBox"/>
                            <FormCheckbox identificativo="Spalle" checked={elencoSedi.Spalle} funzione={(event)=>aggiornaSedi(event)} testo="Spalle" nomeClasse="checkBox"/>
                            <FormCheckbox identificativo="Braccia" checked={elencoSedi.Braccia} funzione={(event)=>aggiornaSedi(event)} testo="Braccia" nomeClasse="checkBox"/>
                            <FormCheckbox identificativo="Gambe" checked={elencoSedi.Gambe} funzione={(event)=>aggiornaSedi(event)} testo="Gambe" nomeClasse="checkBox"/>
                        </Alert>
                    </Col>
                </Row>
            }
            <hr/>
            <FormLabelNumber testo="Durata dell'esercizio in  secondi" funzione={(valore)=>setDurata(valore)} valore={durata} />
            <hr/>
            <FormTextarea testo="Istruzioni Esercizio" valore={istruzioni} funzione={(valore)=>{setIstruzioni(valore)}}/>
            <hr/>
            <Button onClick={()=>invio()} style={{marginLeft:"80%",width:"20%",backgroundColor:"#003769"}}>CONFERMA</Button>
            <br/><br/>
            <Button onClick={()=>props.annulla()} style={{marginLeft:"80%",width:"20%",backgroundColor:"#003769"}}>ANNULLA</Button>
        </div>
        }
        </>
    )

}