import React from 'react'
import {Form,Col,Row,Alert} from 'react-bootstrap'

export default function FormLabelNumber(props){
    return(
        <Row>
            <Col>
                <Alert style={{backgroundColor:"lightgrey"}} className="AlertFormLabel">
                    {props.testo}
                </Alert>
            </Col>
            <Col>
                <Form.Control type="number" placeholder={props.testo} onChange={(event)=>props.funzione(event.target.value)} value={props.valore}/>
            </Col>
        </Row>
    )
}