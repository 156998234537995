import React,{ useState } from 'react'
import {Col,Row} from 'react-bootstrap'
//import components
import Attivita from './Attivita'
import FineAttivita from './FineAttivita'
//import assets
import LogoCBIM from '../../assets/cbim-logo-small.png'

export default function DefinizioneAttivita(props){
    const [fineAttivita,setFineAttivita]=useState(false)
    const [inizio,setInizio]=useState(0)
    const [fine,setFine]=useState(0)

    const ultimaTraining = () =>{
        var data = new Date();
        setFine(data)
        setFineAttivita(true)
    }

    const nuovaSessione = () =>{
        setInizio(0);
        setFineAttivita(false);
    }

    return(
        <>
        <div style={{height:"justyfi",border:"2px solid #003769"}}>
            <div style={{marginLeft:"1%",marginRight:"1%"}}>
            {!fineAttivita?<>
                <div style={{marginTop:"20px",textAlign:"center",fontFamily:"Roboto"}}>
                    <h1><b>Esercitazione</b></h1>
                </div>
                <div style={{marginTop:"50px"}}>
                <Attivita fine={()=>ultimaTraining()} finito={fineAttivita} inizio={inizio} setInizio={(data)=>setInizio(data)} protocollo={props.protocollo} esercizioInCorso={0}/>
                </div>
            </>
            :
            <FineAttivita inizio={inizio} fine={fine} {...props} nuovaSessione={()=>nuovaSessione()} />
            }
            </div>
        </div>
            <Row style={{marginTop:"20px"}}>
                <Col md="5"/>
                <Col md="2">
                    <a href="http://www.cbim.it/" >
                        <img src={LogoCBIM} alt="C.B.I.M." style={{width:"30%",marginLeft:"35%"}}/>
                    </a>
                </Col>
                <Col md="5"/>
            </Row>
        </>
    )
}