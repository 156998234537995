import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faPlus,faBook,faHome,faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {Row,Alert} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './HeaderTerapista.css'

export default function HeaderTerapista(props){
    const nome=props.pagina==="gestione" ? 'Mario Rossi': 'Gemma Giunti'

    return(
        <Alert className="AlertHeader">
          <Row>
            <div style={props.pagina!=="gestione"?{width:"20%"}:{width:"80%"}}>
              <b className="NomeIcona">
                  {nome}&ensp;
              <FontAwesomeIcon icon={faUser} /><span className="DivisoreInit" style={{color:"#003769"}}>|</span>
              </b>
            </div>
            <div style={props.pagina!=="gestione"?{width:"70%"}:{display:"none"}}>
                    <span className="Divisori">|</span>
                  <Link to="/HomeTerapista">
                    <span className="Link">
                      Home 
                    </span>
                    <FontAwesomeIcon  className="Icona" icon={faHome}/>
                  </Link>
                <span className="Divisori">|</span>
                <Link  to="/CreaEsercizio">
                  <span className="Link">
                    Crea Esercizio
                  </span>
                    <FontAwesomeIcon  className="Icona" icon={faPlus}/>
                </Link>
                <span className="Divisori">|</span>
                <Link to="/LibreriaEsercizi">
                  <span className="Link">
                    Libreria Esercizi
                  </span>
                    <FontAwesomeIcon  className="Icona" icon={faBook}/>
                </Link>
                <span className="Divisori">|</span>
                <Link to="/CreaProtocollo">
                  <span className="Link">
                    Crea Protocollo
                  </span>
                    <FontAwesomeIcon  className="Icona" icon={faPlus}/>
                </Link>
                <span className="Divisori">|</span>
                <Link to="/LibreriaProtocolli">
                  <span className="Link">
                    Libreria Protocolli
                  </span>
                    <FontAwesomeIcon  className="Icona" icon={faBook}/>
                </Link>
                <span className="Divisori">|</span>
            </div>
            <div style={props.pagina!=="gestione"?{width:"10%"}:{width:"20%"}}>
              <Link to="/">
              <span className="DivisoreLogout" style={{color:"#003769"}}>|</span>
                <span className="LinkLogout">
                  Logout
                </span>
                  <FontAwesomeIcon  className="Icona" icon={faSignOutAlt}/>
              </Link>
            </div>
          </Row>  
        </Alert>
    )
}