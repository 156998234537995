import React,{useState} from 'react'
import {Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
//import component
import HeaderTerapista from '../../../StaticComponents/HeaderTerapista'
import TabellaEsercizi from '../Tabelle/TabellaEsercizi'
import InfoEsercizio from '../ModalComponent/InfoEsercizio'

export default function LibreriaEsercizi(){
    const [esercizioInfo,setEsercizioInfo]=useState(0)
    return(
        <div style={{marginLeft:"10%",marginRight:"10%"}}>
            {esercizioInfo!==0?<><InfoEsercizio distinzione="libreria" esercizio={esercizioInfo} funzione={()=>setEsercizioInfo(0)}/></>:null}
            <HeaderTerapista pagina="3"/>
            <br/>
            <TabellaEsercizi setEsercizio={(esercizio)=>setEsercizioInfo(esercizio)}/>
                <Button style={{width:"20%",marginLeft:"80%",backgroundColor:"#003769",color:"white"}} as={Link} to="/HomeTerapista">
                    HOME
                </Button>
        </div>
    )
}