import React,{useState} from 'react'
import Registrazione from '../Interfaccia-Admin/RegistrazioneUtente/FormRegistrazione'
import GestioneMedici from '../Interfaccia-Admin/GestioneMedici/GestisciMedici'
import GestionePazienti from '../Interfaccia-Admin/GestionePazienti/GestisciPazienti'
import Header from '../Interfaccia-Admin/StaticComponents/Header'
import {Button,Container} from 'react-bootstrap'
export default function Admin(){
    const [sceltaMenu,setSceltaMenu]=useState(0)

    return(
        <Container>
            <Header/>
        {sceltaMenu===0?
        <div style={{marginTop:"15%"}}>
            <Button variant="info" onClick={()=>setSceltaMenu(3)} style={{width:"25%"}}>
                Gestione Medici
            </Button>
            <br/><br/>
            <Button variant="success" onClick={()=>setSceltaMenu(2)} style={{width:"25%"}}>
                Gestione Pazienti
            </Button>
            <br/><br/>
            <Button variant="warning" onClick={()=>setSceltaMenu(1)} style={{width:"25%"}}>
                Registrazione nuovo utente
            </Button>
        </div>:null}
        <div>
            {sceltaMenu===1?<Registrazione setSceltaMenu={()=>setSceltaMenu(0)}/>:null}
            {sceltaMenu===2?<GestionePazienti setSceltaMenu={()=>setSceltaMenu(0)}/>:null}
            {sceltaMenu===3?<GestioneMedici setSceltaMenu={()=>setSceltaMenu(0)}/>:null}
        </div>
        </Container>
    )
}