import React from 'react'
import './index.css'
export default function index(props){

    //{props.sezione}

    return(
        <div className="container">
            <div className="loader"/>
        </div>
    )

}