import React,{useState} from 'react'
import {Dropdown} from 'react-bootstrap'

export default function DropdownButton(props){
    const scelte=[]
    const [importaTesto,setImportaTesto]=useState(props.testoPredefinito)

    function onSelect(event){
        if(event.target.id==="Vedi tutti") setImportaTesto(props.testoPredefinito)
        else setImportaTesto(event.target.id)
        props.funzione(event.target.id)
    }

    props.opzioni.map(
        (opzione)=>scelte.push(
                <Dropdown.Item id={opzione} key={opzione} onClick={onSelect}>
                          {opzione}
                </Dropdown.Item>
        )
    )
    
  return ( 
    <Dropdown>
        <Dropdown.Toggle style={props.stile}>
            {importaTesto}
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {scelte}
        </Dropdown.Menu>
    </Dropdown>
  )
}