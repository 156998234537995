import React from 'react'
import {Button, Container,Row,Col} from 'react-bootstrap'
import './LoginStyle.css'
import LogoCBIM from '../assets/cbim-logo-small.png'
function Login(){
    return(
        <Container style={{marginTop:"15%"}}>
            <div style={{marginTop:"10%",marginLeft:"20%",marginRight:"20%",color:"white"}}>{/*Div per l'inserimento delle credenziali*/}
                <br/>
                <Row>
                    <Col md="4" >
                        <a href="/HomeTerapista"><Button style={{width:"90%"}} >Configurazione</Button></a>
                    </Col>
                    <Col md="4">
                    <a href="/GestionePazienti"><Button style={{width:"90%"}} >Gestione Pazienti</Button></a>
                    </Col>
                    <Col md="4">
                        <a href="/HomePaziente"><Button style={{width:"90%"}} >Paziente</Button></a>
                    </Col>
                </Row>
                <br/>
            </div>
            <Row>
                <Col md="5"/>
                <Col md="2">
                    <a href="http://www.cbim.it/" >
                        <img src={LogoCBIM} alt="C.B.I.M." style={{width:"4vw",maxWidth:"100px",marginLeft:"35%",borderRadius:"100%",marginBottom:"2vw"}}/>
                    </a>
                </Col>
                <Col md="5"/>
            </Row>
        </Container>
       
    )
}

/*ELEMENTO NUOVO*/
export default Login;
/*ELEMENTO NUOVO*/