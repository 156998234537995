import React from 'react'
import {Alert,Collapse} from 'react-bootstrap'

export default function AlertEsercizio(props){

    return (
        <div>
        <Alert style={{backgroundColor:"lightgrey"}}>
            {props.esercizio.titolo}
        </Alert>
        
        {props.posizione===0 && props.esercizioInCorso===0?
            <>
                {props.esercizio.istruzioni}
                <br/><br/>
            </>
            :
            <>
                    <Collapse in={props.posizioneInCorso===props.posizione} >
                        <div>
                        {props.esercizio.istruzioni}
                        <br/><br/>
                        </div>
                    </Collapse>
                    
            </>
        }
        </div>
    )
}