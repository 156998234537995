import React, { Component } from 'react';


import api from '../api';

const PazientiContext = React.createContext();

const INITIAL_STATE = { elencoPazienti : null , ultimoProtocollo : null , protocolloTriggered:null ,assegnazioneTriggered:null , trainingPaziente: null , trainingTriggered:null};

class PazientiProvider extends Component {
  state = INITIAL_STATE;


  getAllPazienti = async () =>{
      const { data } = await api.getPazienti();
      var Pazienti = data.nameValuePairs.pazienti.values;
      const arrayDefinitivo = []
      Pazienti.forEach(oggetto=>{
        arrayDefinitivo.push(oggetto.nameValuePairs)
      })
      
      this.setState({ elencoPazienti : arrayDefinitivo})
  }

  getUltimoProtocollo = async (idprotocollo,idpaziente) =>{
    this.setState({protocolloTriggered : idprotocollo})
    var richiesta = {protocollo:idprotocollo,paziente:idpaziente}
    const { data } = await api.getUltimoProtocollo(richiesta);
    this.setState({ultimoProtocollo : data})
  }

  assegnaProtocollo = async (assegnazione) =>{
    this.setState({assegnazioneTriggered:assegnazione,elencoPazienti:null});
    await api.assegnaProtocollo(assegnazione);
  }

  getTrainingPaziente = async (paziente) =>{
    var richiesta = {paziente:paziente}
    this.setState({trainingTriggered:paziente});
    const { data } = await api.getTrainingPaziente(richiesta);
    this.setState({trainingPaziente : data})
  }

  render() {
    return (
      <PazientiContext.Provider
        value={{...this.state , getAllPazienti:this.getAllPazienti , getUltimoProtocollo:this.getUltimoProtocollo ,
           assegnaProtocollo : this.assegnaProtocollo , getTrainingPaziente : this.getTrainingPaziente }}
      >
        {this.props.children}
      </PazientiContext.Provider>
    )
  }
}
const PazientiConsumer = PazientiContext.Consumer;

export { PazientiProvider , PazientiConsumer , PazientiContext }