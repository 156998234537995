import React from 'react'
import {faCheckCircle,faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getHeaderSedeTabella = (elencoSedi) =>{
    if(elencoSedi!==undefined && elencoSedi!==null){
        var body = [];
        elencoSedi.forEach((sede)=>{
            body.push(<th key={"Header"+sede.ID}>{sede.nome}</th>)
        })
        return body
    }
}

const getBodySedeTabella = (elencoSedi,esercizio) =>{
    var sediInteressate = esercizio.sediInteressate

    const iconaInteressata = <FontAwesomeIcon icon={faCheckCircle} style={{color:"green"}}/>
    const iconaNonInteressata = <FontAwesomeIcon icon={faTimesCircle} style={{color:"red"}}/>

    if(elencoSedi !== undefined && elencoSedi !== null && sediInteressate !== undefined && sediInteressate !== null){
        var body = [];
        elencoSedi.forEach((sede)=>{
            var sedeInteressataEsercizio = false
            sediInteressate.forEach((controlloInteresse)=>{ 
                if(controlloInteresse.ID === sede.ID) sedeInteressataEsercizio = true
             })
            body.push(<td key={"Sede"+sede.ID+"Esercizio"+esercizio.ID}>
                {sedeInteressataEsercizio === true ? iconaInteressata : iconaNonInteressata }
            </td>)
        })
        return body
    }
}

const getCheckFiltriEsercizi = (props) =>{
    
    //titolo
    var checkTitolo = false
    var esercizioTitolo = props.esercizio.titolo.toUpperCase();
    var filtroTitolo = props.filtroTitolo.toUpperCase();
    if(filtroTitolo==="" || esercizioTitolo.indexOf(filtroTitolo)>-1) checkTitolo = true

    //difficolta
    var checkDifficolta = false
    var esercizioDifficolta = props.esercizio.livello
    var filtroDifficolta = props.filtroDifficolta
    if(filtroDifficolta==="" || filtroDifficolta===esercizioDifficolta || filtroDifficolta==="Vedi tutti") checkDifficolta = true

    //fase di training
    var checkFase = false
    var esercizioFase = props.esercizio.faseTraining
    var filtroFase = props.filtroFase
    if(filtroFase==="" || filtroFase===esercizioFase || filtroFase==="Vedi tutti") checkFase = true

    if(checkFase===true && checkTitolo===true && checkDifficolta===true) return true
    else return false
}

const getMessaggioPatologie = (elencoSpecialita,comparer) =>{
    var testoTabella = ""
    elencoSpecialita.forEach((specialita)=>{
        var patologieSpecialita = specialita.patologia;
        patologieSpecialita.forEach((patologia)=>{
            comparer.forEach((associazione)=>{
                if(associazione.idPatologia === patologia.ID) {
                    if(testoTabella==="") testoTabella = patologia.nome
                    else testoTabella += " / "+patologia.nome
                }
            })
        })
    })
    return testoTabella
}

const getSediEsercizio = (esercizio,elencoSedi) => {
    const iconaInteressata = <FontAwesomeIcon icon={faCheckCircle} style={{color:"green"}}/>
    const iconaNonInteressata = <FontAwesomeIcon icon={faTimesCircle} style={{color:"red"}}/>
    const sediEsercizio = esercizio.sediInteressate
    const body = []
    elencoSedi.forEach((sede)=>{
        var check = false;
        sediEsercizio.forEach((sedeEsercizio)=>{
            if(sede.ID === sedeEsercizio.ID) check = true;
        })
        var row =<tr key={sede.ID+"RowSede"}><td key={sede.ID+"Sede"}>{sede.nome}</td><td key={sede.ID+"Valore"}>{check=== true ? iconaInteressata : iconaNonInteressata}</td> </tr>
        body.push(row);
    })

    return body
}

const getIdSede = (sedeText,sedeValue,sediEsercizio,elencoSedi) =>{
    var nuovoTesto = sediEsercizio;
    if(sedeValue){
        elencoSedi.forEach(sede=>{
            var nomeSede = sede.nome.toLowerCase();
            if(nomeSede===sedeText){
                if(nuovoTesto === "") nuovoTesto=sede.ID
                else nuovoTesto=nuovoTesto+"$"+sede.ID
            }
        })
    }
    return nuovoTesto
}

const getCheckInvioEsercizio = (esercizio) =>{
    var valido = false
    var messaggio="Campi non validi:"
        if(esercizio.titolo==="") messaggio+="\n-Titolo"
        if(esercizio.descrizione==="") messaggio+="\n-Descrizione"
        if(esercizio.livelloDifficolta==="") messaggio=messaggio+"\n-Livello di difficolta'"
        if(esercizio.faseTraining==="") messaggio=messaggio+"\n-Fase training"
        if(!esercizio.testa && !esercizio.collo && !esercizio.spalle && !esercizio.braccia && !esercizio.gambe) messaggio=messaggio+"\n-Sede/i di interesse"
        if(esercizio.durata===0 || esercizio.durata==="") messaggio+="\n-Durata dell'esercizio"
        if(esercizio.istruzioni==="") messaggio=messaggio+"\n-Istruzioni dell'esercizio"
        if(esercizio.video==="") messaggio=messaggio+"\n-Video dell'esercizio"
        if(messaggio==="Campi non validi:") valido=true
        else valido=false

        if(valido){//CREO OGGETTO DA SALVARE
            try{
                var patologie = ""
                esercizio.elencoPatologie.forEach(specialita=>{
                    specialita.patologie.forEach(patologia=>{
                        if(patologia.checked === true){
                            if(patologie === "") patologie=patologia.ID
                            else patologie=patologie+"$"+patologia.ID
                        }
                    })
                })

                var sedi = ""
                sedi = getIdSede("braccia",esercizio.braccia,sedi,esercizio.sedi);
                sedi = getIdSede("collo",esercizio.collo,sedi,esercizio.sedi);
                sedi = getIdSede("gambe",esercizio.gambe,sedi,esercizio.sedi);
                sedi = getIdSede("spalle",esercizio.spalle,sedi,esercizio.sedi);
                sedi = getIdSede("testa",esercizio.testa,sedi,esercizio.sedi);
                
                var object ={
                    titolo : esercizio.titolo,
                    descrizione : esercizio.descrizione,
                    livello : esercizio.livelloDifficolta,
                    faseTraining : esercizio.faseTraining,
                    durata : esercizio.durata,
                    istruzioni : esercizio.istruzioni,
                    patologie : patologie,
                    sedi : sedi,
                    valido : valido,
                    url : "gWR46oqTbmc"
                }

                return object

            }catch(error){
                var response = {
                    valido:false,
                    messaggio:"Errore nel'elaborazione dei dati,si prega effettuare il logout e riprovare."
                }
                return response    
            }
        }else{
            var response = {
                valido:valido,
                messaggio:messaggio
            }
            return response
        }
}

const getPatologieText = (PATOLOGIE_ESERCIZIO,SPECIALITA) =>{
    const body=[]
    SPECIALITA.forEach((specialita)=>{
        PATOLOGIE_ESERCIZIO.forEach(assegnata =>{
            const PATOLOGIE = specialita.patologia
            const bodySpecialita = []
            PATOLOGIE.forEach(patologia=>{
                if(patologia.ID === assegnata.idPatologia) bodySpecialita.push(<li key={patologia.ID+"patologia"}>{patologia.nome}</li>)
            })
            if(bodySpecialita.length>0){
                body.push(<div key={"Specialita"+specialita.idSpecialita}>
                    Patologie della specialità <b>{" "+specialita.nomeSpecialita}</b>
                    <br/>{bodySpecialita}<br/>
                </div>)
            }
        })
    })
    return body
}

export default { getHeaderSedeTabella , getBodySedeTabella , getCheckFiltriEsercizi , getMessaggioPatologie , getPatologieText ,getSediEsercizio ,getCheckInvioEsercizio}