import React from 'react'

const getHeaderTabella = () =>{
    const header=[]
    const rowHeader=[]
    rowHeader.push(<th key="HCognome">Cognome</th>)
    rowHeader.push(<th key="HNome">Nome</th>)
    rowHeader.push(<th key="HCF">Codice Fiscale</th>)
    rowHeader.push(<th key="HDataNascita">Data<span style={{color:"white"}}>_</span>Nascita</th>)
    rowHeader.push(<th key="HLuogoNascita">Luogo Nascita</th>)
    rowHeader.push(<th key="HEmail">Email</th>)
    rowHeader.push(<th key="HInfo">Info</th>)
    header.push(<tr key="RH">{rowHeader}</tr>)

    return header
}

const getPazienteTabella = (paziente) =>{
    
    var giorno = new Date(paziente.dataNascita).getDate();
    var mese = new Date(paziente.dataNascita).getMonth()+1;
    var anno = new Date(paziente.dataNascita).getFullYear();
    var dataNascita = giorno+"-"+mese+"-"+anno

    var row=[]
    row.push(<td key={"PCognome"+paziente.id}>{paziente.cognome}</td>)
    row.push(<td key={"PNome"+paziente.id}>{paziente.nome}</td>)
    row.push(<td key={"PCF"+paziente.id}>{paziente.codiceFiscale}</td>)
    row.push(<td key={"PDataNascita"+paziente.id}>{dataNascita}</td>)
    row.push(<td key={"PLuogoNascita"+paziente.id}>{paziente.luogoNascita}</td>)
    row.push(<td key={"PEmail"+paziente.id}>{paziente.email}</td>)
    
    return row
}

const controlloFiltri = (paziente,filtro) =>{
    var responce = false
    var NomeCognome = paziente.nome.toUpperCase() +" "+ paziente.cognome.toUpperCase()
    var CognomeNome = paziente.cognome.toUpperCase() +" "+ paziente.nome.toUpperCase()
    var CodiceFiscale = paziente.codiceFiscale.toUpperCase()
    var filtroUpper = filtro.toUpperCase()
    if(filtro==="") responce = true
    else{
        var checkNomeCognome = NomeCognome.indexOf(filtroUpper)>-1 ? true : false 
        var checkCognomeNome = CognomeNome.indexOf(filtroUpper)>-1 ? true : false
        var checkCodiceFiscale = CodiceFiscale.indexOf(filtroUpper)>-1 ? true : false

        if(checkCodiceFiscale || checkCognomeNome || checkNomeCognome ) responce = true
        else responce = false
    }

    return responce
}

const getDataNacita = (data) =>{
    var DataNascita = new Date(data)
    
    var giorno = DataNascita.getDate()
    if( giorno < 10 ) giorno = "0" + giorno;
    var mese = DataNascita.getMonth()+1
    if( mese < 10 ) mese = "0" + mese;
    var anno = DataNascita.getFullYear()

    var dataReturn = giorno + "-" + mese + "-" + anno

    return dataReturn
}

export default {getHeaderTabella , getPazienteTabella , controlloFiltri , getDataNacita}