import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus,faBook,faBars } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './CollegamentiMenuStyle.css'

export default function CollegamentiMenu(props){// #003769;
    return(
    <div className="box">
        <Link to={props.pagina}>
            <Alert role="button" className="Header">    
                    <div className="testo">
                        {props.testo}
                    </div>
                <br/>
                <strong>
                    <span className="spanIcon">
                        {props.icon==="aggiungi"?
                            <FontAwesomeIcon icon={faPlus} className="icon"/>
                            :null}

                        {props.icon==="gestione"?
                            <FontAwesomeIcon icon={faBars} className="icon"/>
                            :null}

                        {props.icon==="libreria"?
                            <FontAwesomeIcon icon={faBook} className="icon"/>
                            :null}

                    </span>
                </strong>
            </Alert>
        </Link>
    </div>
    )
}