import React from 'react'
import {Badge,Col,Row,Button} from 'react-bootstrap' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt,faStar,faArrowUp,faArrowDown } from '@fortawesome/free-solid-svg-icons';

export default function ComponentEsercizio(props){
    const esercizio=props.esercizio
    const posizione=props.posizione
    const ripetizioni=props.ripetizioni
    const durataEsercizio=props.ripetizioni*esercizio.durata
    const durataEsercizioMinuti=((durataEsercizio-durataEsercizio%60)/60).toFixed(0)+" minuti"
    const durataEsercizioSecondi=(durataEsercizio%60)+" secondi"
    //icon in base ai livelli
    const icona = <FontAwesomeIcon icon={faStar} style={{color:"black"}}/>

    const getIconaDifficolta=(livello)=>{
        if(livello==="Semplice") return icona
        if(livello==="Intermedio") return <>{icona}{icona}</>
        if(livello==="Avanzato") return <>{icona}{icona}{icona}</>
    }
    
    const getZone=(esercizio)=>{
        var messaggio=""
        esercizio.sediInteressate.forEach(sede=>{
            if(messaggio==="") messaggio=sede.nome
            else messaggio=messaggio+","+sede.nome
        })
        return messaggio
    }//getZone()

    const cambiaRipetizione=(event)=>{
        var valore = event.target.value
        if(valore===""){
            props.aggiornaRipetizione(posizione,valore)
        }else{
            valore=parseInt(valore)
            //se numero (non piu di 10 ripetizioni e non meno di 1 ripetizione)
            if(valore<1 || valore>10) alert("Errore:\nIl valore "+valore+" non e' valido.\nModifica non avvenuta")
            else{
                props.aggiornaRipetizione(posizione,valore)
            }
        }
    }

    return(
    <h3>
        <Badge style={{width:"100%",textAlign:"left",border:"2px solid #003769"}}>
            <Row style={{marginBottom:"5px",marginTop:"5px"}}>
                <Col md="1">
                    <Button style={{backgroundColor:"#003769"}} disabled={props.posizione===0?true:false} onClick={()=>props.moveUp(posizione,esercizio)}>
                        <FontAwesomeIcon icon={faArrowUp} />
                    </Button>
                    <br/><br/>
                    <Button style={{backgroundColor:"#003769"}} disabled={props.posizione===props.lunghezza-1?true:false} onClick={()=>props.moveDown(posizione,esercizio)}>
                        <FontAwesomeIcon icon={faArrowDown} />
                    </Button>
                </Col>
                <Col md="5">
                    {esercizio.titolo}
                    <br/><br/>
                    Durata:&nbsp;{durataEsercizioMinuti}&nbsp;{durataEsercizioSecondi}&nbsp;<br/>
                    <br/>
                    Ripetizioni:<input type="number" id="LabelRipetizioni" value={ripetizioni} min={1} max={10} onChange={(event)=>cambiaRipetizione(event)}/>
                </Col>
                <Col md="4">
                    Livello:{getIconaDifficolta(props.esercizio.livello)}<br/>
                    <br/>
                    Fase di training:{esercizio.faseTraining}
                    <br/>
                    <br/>
                    Zone interessate:{getZone(esercizio)}
                </Col>
                <Col>
                    <br/><br/>
                    <Button style={{backgroundColor:"#003769"}} onClick={()=>props.rimuovi(posizione)}>
                        RIMUOVI <FontAwesomeIcon icon={faTrashAlt} style={{color:"white"}}/>
                    </Button>
                </Col>
            </Row>
        </Badge>
        <br/>
        <hr/>
    </h3>
    )

}