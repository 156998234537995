import React from 'react'
import {Row,Col,Dropdown,Alert} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'

export default function Header(){
    
    return(
        <Alert style={{width:"100%",position:"relative",backgroundColor:"white"}}>
          <Row>
              <Col md="8">
                <h1>ADIUVANT</h1>
              </Col>
              <Col style={{fontSize:"1vw"}} md="4">
                <Dropdown>
                    <Dropdown.Toggle id="dropdown" style={{backgroundColor:"white",color:"black"}}>
                        <FontAwesomeIcon icon={faBars}/>&ensp;MENU
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link}to="/">
                                Logut
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>  
        </Alert>
    )
}