import React from 'react'
import {Alert,Row,Col,Form} from 'react-bootstrap'
import './Style.css'

export default function FormTextarea(props){

    return(
        <Row>
            <Col>
                <Alert style={{backgroundColor:"lightgrey"}}>
                    {props.testo}
                </Alert>
            </Col>
            <Col>
                <Form.Control as="textarea" rows="4" placeholder={props.place} onChange={(event)=>props.funzione(event.target.value)} value={props.valore}/>
            </Col>
        </Row>
    )
}