import React from 'react'
import {Alert,Collapse} from 'react-bootstrap'

export default function AlertEsercizio(props){
    return(
        <div>
            <Alert style={{backgroundColor:"grey",color:"white"}}>
                {props.esercizio.titolo}
            </Alert>
            {props.posizione===props.posizioneInCorso?
            <Collapse in={props.posizione===props.posizioneInCorso} appear>
                <div>
                {props.esercizio.istruzioni}
                <br/><br/>
                </div>
            </Collapse>
            :null}
        </div>
    )
}