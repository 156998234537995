import React,{useState} from 'react'
import {PazientiConsumer} from '../../../../context/PazientiContext'
import {ProtocolliConsumer} from '../../../../context/ProtocolliContext'
//components
import TabellaPazienti from '../Tabelle/TabellaPazienti'
import HeaderTerapista from '../../../StaticComponents/HeaderTerapista'
import InfoPaziente from '../ModalComponent/InfoPaziente'
//Gestore pazineti
import Gestore from '../GestionePazientiApi/Gestore'

export default function GestionePazienti(props){
const [infoPaziente,setInfoPaziente]=useState(0);
const [inizializzati,setInizializzati]=useState(false);
    return(
    <div style={{marginLeft:"10%",marginRight:"10%"}}>
        {PazientiConsumer._currentValue.elencoPazienti === null || ProtocolliConsumer._currentValue.elencoProtocolli === null ||  !inizializzati ?        
        <Gestore tipo="getter" fine={()=>setInizializzati(true)} />
        :
        <div>
            <HeaderTerapista pagina="gestione"/>
            <br/> 
            <TabellaPazienti idMedico={"1"} setInfoPaziente={(paziente)=>setInfoPaziente(paziente)}/>
            <br/>
            {infoPaziente!==0 ? <InfoPaziente paziente={infoPaziente} open={true} close={()=>setInfoPaziente(0)}/>:null} 
        </div>
        }
    </div>
    )
}