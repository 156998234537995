import React,{useState} from 'react'
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause} from '@fortawesome/free-solid-svg-icons';
import './VimeoPlayer.css'

export default function VimeoPlayer(props){

    const [playerObject,setPlayerObject] = useState(null);

    const getVideoObject = (event) =>{
        //GESTIONE DEL VIDEO TRAMITE L'OGGETTO CREATO DAL PLAYER
        setPlayerObject(event);
        props.setReady();
    }

    return(
        <div className='embed-container'>            
            <ReactPlayer url={props.url} controls={false} playing={props.playing} onReady={(event)=>getVideoObject(event)} loop={true} onError={()=>props.setErrore()} />
            <div className="preventAction" />
            {props.playing ? null : 
            <div className="pausaViewer">
                <div className="scritta">
                <FontAwesomeIcon icon={faPause}/>
                <br/>IN PAUSA
                </div>
            </div>}
        </div>
        )
}