import React from 'react'
import FormRegistrazione from '../RegistrazioneUtente/FormRegistrazione' 
import {Button} from 'react-bootstrap'
export default function GestisciPazienti(props){
    
    return(
        <div>
            Gestione dei Pazienti
            <Button variant="danger" onClick={props.setSceltaMenu}>INDIETRO</Button>
        </div>
    )
}