import React from 'react'
import {Row,Col,Alert} from 'react-bootstrap'
//import component
import Dropdown from '../../../../components/BasicComponents/DropdownButton'
export default function FormDropdown(props){
    return(
        <Row>
            <Col>
                <Alert  style={{backgroundColor:"lightgrey"}}>
                    {props.testo}
                </Alert>
            </Col>
            <Col>
                <Dropdown testoPredefinito={props.testoDropdown} opzioni={props.opzioni} funzione={(value)=>props.funzione(value)} stile={{width:"50%",backgroundColor:"#003769",color:"white"}}/>
            </Col>
        </Row>
    )
}