import React from 'react'
import ComponentEsercizio from '../EserciziProtocollo/ComponentEsercizio'
export default function ElencoEserciziSelezionati(props){
    const corpo =[]
    const componiCorpo = () =>{
        if(props.esercizi !== null){
            var contatoreEsercizi=0
            props.esercizi.forEach((esercizio)=>{
                var contatoreRipetizioni=0
                var valoreRipetizioni
                props.ripetizioni.forEach((ripetizione)=>{
                    if(contatoreEsercizi===contatoreRipetizioni) valoreRipetizioni=ripetizione
                    contatoreRipetizioni++
                })
            corpo.push(
            <div key={contatoreEsercizi}>
                <ComponentEsercizio esercizio={esercizio} lunghezza={props.esercizi.length} posizione={contatoreEsercizi} ripetizioni={valoreRipetizioni}
                rimuovi={(posizione)=>props.rimuovi(posizione)} 
                aggiornaRipetizione={(posizione,valore)=>props.aggiornaRipetizione(posizione,valore)}
                moveUp={(posizione,esercizioUp)=>props.moveUp(posizione,esercizioUp)}
                moveDown={(posizione,esercizioDown)=>props.moveDown(posizione,esercizioDown)}
                />
            </div>
            )
        contatoreEsercizi++
        })
        }

        return(<>{corpo}</>)
    }

    return(
        <div>
            {componiCorpo()}
            <br/>
        </div>
    )
}