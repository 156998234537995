import React,{useState} from 'react'
import {Alert} from 'react-bootstrap'
import HeaderPaziente from '../Interfaccia-Paziente/StaticComponents/HeaderPaziente'
import DefinizioneAttivita from '../Interfaccia-Paziente/Components-Paziente/DefinizioneAttivita'
import ComponentDemo from '../Interfaccia-Paziente/ComponentDemo/ComponentDemo'
import Gestore from '../Interfaccia-Paziente/Gestore/Gestore'
import {PazienteConsumer} from '../context/PazienteContext'

export default function HomePaziente(){
    
    const [paziente,setPaziente]=useState(1);
    const [caricamentoDati,setCaricamentoDati]=useState(true);
    const [errore,setErrore]=useState(null);
    const [caricando,setCaricando]=useState(false);

    const cambia = (valore) =>{
        setPaziente(valore);
        setCaricamentoDati(true);
        setErrore(null);
    }

    return(
        <div>
            <div style={{marginLeft:"10%",marginRight:"10%"}}>
            {errore !== null?
                <div>
                    <HeaderPaziente />
                    <br/>
                    <Alert variant="danger" style={{marginTop:"max(100px,10%)"}}>
                        Errore nella comunicazione:<br/>{errore}
                    </Alert>
                </div>
                :
                    PazienteConsumer._currentValue.idPaziente !== paziente || caricamentoDati===true ?
                        <Gestore setCaricando={(valore)=>setCaricando(valore)} setCaricamentoDati={(valore)=>setCaricamentoDati(valore)} tipo="getter" idpaziente={paziente} errore={(err)=>setErrore(err)}/>
                        : 
                        <div>
                            <div style={{position:"absolute",zIndex:"3",width:"100%"}}>
                                <HeaderPaziente/>
                            </div>
                            <br/>
                            <div id="training" style={{width:"100%",position:"relative",zIndex:"2",marginTop:"80px"}}>
                                <DefinizioneAttivita protocollo={PazienteConsumer._currentValue.protocollo}/>
                            </div>
                        </div>
            }
            <br/>
            <div style={caricando ? {display:"none"}:{}}>
                <ComponentDemo cambiaPaziente={(valore)=>cambia(valore)}/>
            </div>
            </div>
        </div>
    )
}