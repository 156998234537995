import React, { Component } from 'react';


import api from '../api';

const PatologieContext = React.createContext();

const INITIAL_STATE = { elencoPatologie : null , inizializzato : false};

class PatologieProvider extends Component {
  state = INITIAL_STATE;


    getAllPatologie = async () =>{
      const { data } = await api.getPatologie();
      var patologie = data.nameValuePairs.specialita
      this.setState( { elencoPatologie:patologie,inizializzato:true } );
    }

  render() {
    return (
      <PatologieContext.Provider
        value={{...this.state , getAllPatologie:this.getAllPatologie }}
      >
        {this.props.children}
      </PatologieContext.Provider>
    )
  }
}
const PatologieConsumer = PatologieContext.Consumer;

export { PatologieProvider , PatologieConsumer , PatologieContext }