import React, { useState } from 'react'
import {Alert,Button,Row,Col} from 'react-bootstrap'
//import MostraVideo from './MostraVideo'
import VimeoComponent from './VimeoComponents/Player/VimeoComponent'
import './StyleVideo.css'

var identificativo=0;
var posizione = null;
export default function Video(props){

    const [inEsecuzione,setInEsecuzione]=useState(false);
    const [annullaTimer,setAnnullaTimer]=useState(false)
   

    const prossimo = () =>{
        setInEsecuzione(false)
        if(props.pagina!=="paziente") props.nextVideo()        
    }

    const prossimoTraining = (es) =>{
        if(es.posizione === posizione){
            setInEsecuzione(false);
            setAnnullaTimer(true);
            props.nextVideo(props.esercizio);
        }
    }

    const precedente = () =>{
        setInEsecuzione(false)
        props.prevVideo()
    }

    const getVideo=()=>{
        cambiaVideo(props.video)
    }

    const ricomincia=()=>{
        setInEsecuzione(false);
        props.ricomincia()
    }

    const getRicominciaTraining = () =>{
        if(inEsecuzione) setInEsecuzione(false);
        return <Button style={{backgroundColor:"#003769",color:"white",width:"90%",marginLeft:"10%"}} onClick={()=>ricomincia()} >RICOMINCIA TRAINING</Button>
    }

    const cambiaPosizione = (nuovaPosizione) =>{
        posizione = nuovaPosizione;
    }

    const cambiaVideo=(id)=>{
        identificativo=id;
    }

    return(
        <Alert style={{width:"100%"}}>
            <div className="VideoContainer"> 
            {identificativo === 0 || identificativo !== props.video ? getVideo():null}
            {props.pagina==="paziente" && posizione !== props.esercizio.posizione ? cambiaPosizione(props.esercizio.posizione):null}
                {identificativo!==0?
                <VimeoComponent {...props} annullaTimer={annullaTimer} setAnnullaTimer={()=>setAnnullaTimer(true)} prossimo={()=>prossimo()} prossimoTraining={(es)=>prossimoTraining(es)} url={identificativo} inEsecuzione={inEsecuzione} setInEsecuzione={(value)=>setInEsecuzione(value)}/>:null}
            </div>
            <br/>
            <br/>
            <Row>
                <Col>
                {props.pagina==="GestioneEserciziVideo"?
                        <Button style={{backgroundColor:"#003769",color:"white",width:"90%"}} onClick={()=>precedente()} className="buttonAzioneVideo">ESERCIZIO PRECEDENTE</Button>
                        :null
                    }
                {props.pagina==="paziente"?
                        <Button className="buttonAzioneVideo" onClick={()=>prossimoTraining(props.esercizio)} disabled={props.pagina==="paziente" && props.finito===true ? true : false} style={{backgroundColor:"#003769",color:"white",width:"90%"}}>ESERCIZIO SUCCESSIVO</Button>
                        :null
                }
                </Col>
                <Col>
                    {props.pagina!=="paziente" && props.pagina!=="InfoEsercizio" ?
                        <Button  className="buttonAzioneVideo" onClick={()=>prossimo()} disabled={props.pagina==="paziente" && props.finito===true ? true : false} style={{backgroundColor:"#003769",color:"white",width:"90%",marginLeft:"10%"}}>ESERCIZIO SUCCESSIVO</Button>
                        :null
                    }
                    {props.pagina==="paziente" ?
                            props.finito?
                            <>
                            {getRicominciaTraining()}
                            <br/><br/>
                            <Button variant="danger" onClick={()=>props.fineTraining()} style={{width:"90%",marginLeft:"10%"}} >FINE TRAINING</Button>
                            </>
                            :
                            <Button variant="danger" onClick={()=>props.fineTraining()} style={{width:"90%",marginLeft:"10%"}} >FINE TRAINING</Button> 
                        :
                        null
                    }
                </Col>
                
            </Row>
        </Alert>)
}