import React,{useState} from 'react'
import Modal from 'react-modal'
import {Button,Table,Alert,Row,Col,Form} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {ProtocolliConsumer} from '../../../../context/ProtocolliContext';
import InfoEsercizio from './InfoEsercizio'
import InfoProtocollo from './InfoProtocollo'
import TabellaProtocolli from '../Tabelle/TabellaProtocolli'
import Anagrafe from '../DatiPaziente/Anagrafe'
import SelectComponent from '../DatiPaziente/ProtocolloInCorso/SelectComponent'
import SelectComponentTraining from '../DatiPaziente/ElencoTraining/SelectComponent'
import Gestore from '../GestionePazientiApi/Gestore'

Modal.setAppElement("#root");

export default function InfoPaziente(props){
const [modalIsOpen,setModalIsOpen]=useState(props.open)
const [protocolloAssegnato,setProtocolloAssegnato]=useState({ID:props.paziente.protocollo});
const [vediSottomenu,setVediSottoMenu] = useState(0);
const [allProtocolli,setAllProtocolli] = useState(null)
const [vediInfoEsercizio,setVediInfoEsercizio]=useState(0);
const [vediInfoProtocollo,setVediInfoProtocollo]=useState(0);
const [invioAssegnazione,setInvioAssegnazione]=useState(false);
const [numeroSettimane,setNumeroSettimane] = useState(0);
const [numeroGiorniSettimana,setNumeroGiorniSettimana] = useState(0);

const onClose = () =>{
    props.close()
    setModalIsOpen(false)
}

const cambiaAssegnazione = (protocollo) =>{
    setProtocolloAssegnato(protocollo);
    setNumeroSettimane(protocollo.numeroSettimane);
    setNumeroGiorniSettimana(protocollo.frequenza);
}

const cambiaFocus = (event) =>{
    var nome = event.target.id
    if(vediSottomenu === nome) setVediSottoMenu(0);
    else setVediSottoMenu(nome)
}

const fineAssegnazione = () =>{
    const NOMINATIVO = props.paziente.cognome + " " +props.paziente.nome
    const TITOLO_PROTOCOLLO = protocolloAssegnato.titolo
    alert("Il protcollo ' "+TITOLO_PROTOCOLLO+" ' e' stato assegnato a "+NOMINATIVO);
    onClose()
}

const changeFrequenza = (event) =>{
    const id = event.target.id;
    const valore = event.target.value;
    if(id==="settimane"){
        if(valore !== ""){
            if(valore > 50 || valore < 1){
                alert("Attenzione: il valore selezionato ("+valore+") non e' valido per la durata delle settimane.\nValori ammissibili da 1 a 50 (estremi compresi)");
                if(valore > 50) setNumeroSettimane(50);
                if(valore < 1) setNumeroSettimane(1);
            }else{
                setNumeroSettimane(valore);
            }
        }else{
            setNumeroSettimane("");
        }
    }else{//giorni
        if(valore !== ""){
            if(valore > 7 || valore < 1){
                alert("Attenzione: il valore selezionato ("+valore+") non e' valido per la frequenza settimanale.\nValori ammissibili da 1 a 7 (estremi compresi)");
                if(valore > 7) setNumeroGiorniSettimana(7);
                if(valore < 1) setNumeroGiorniSettimana(1);
            }else{
                setNumeroGiorniSettimana(valore);
            }
        }else{
            setNumeroGiorniSettimana("");
        }
    }

}

const confermaAssegnazione = () =>{
    var valido = true;
    var messaggio = "";
    var IntestazioneMessaggio = "Errore : \n"

    if(numeroSettimane >50 || numeroSettimane < 1 ){
        if(valido = true){
            valido = false;
            messaggio = IntestazioneMessaggio + "Il numero di settimane  selezionato ("+numeroSettimane+") non e' valido.\nValori ammissibili da 1 a 50 (estremi compresi). \n"
        }else{
            messaggio = "Il numero di settimane  selezionato ("+numeroSettimane+") non e' valido.\nValori ammissibili da 1 a 50 (estremi compresi). \n"
        }
    }
    
    if(numeroGiorniSettimana >7 || numeroGiorniSettimana < 1 ){
        if(valido = true){
            valido = false;
            messaggio = IntestazioneMessaggio + "La frequenza settimanale selezionata ("+numeroGiorniSettimana+") non e' valida.\nValori ammissibili da 1 a 7 (estremi compresi). \n"
        }else{
            messaggio = "La frequenza settimanale selezionata ("+numeroGiorniSettimana+") non e' valida.\nValori ammissibili da 1 a 7 (estremi compresi). \n"
        }
    }

    if(valido) setInvioAssegnazione(true)
    else alert(messaggio)
}

const estraiProtocollo = (idSelezionato) =>{
    var protocolloPresente = false;
    var protocolloEstratto = null;
    if(allProtocolli !== null && allProtocolli !== undefined){
        if(allProtocolli.length > 0){
            allProtocolli.forEach(protocollo=>{
                if(protocollo.ID == idSelezionato) {
                    protocolloEstratto = protocollo
                    protocolloPresente = true
                }
            })
            if(protocolloPresente) setVediInfoProtocollo(protocolloEstratto)
            else alert("Il protocollo richiesto non e' stato trovato")
        }else{
            alert("Non e' stato trovato alcun protocollo salvato" )
        }
    }else{
        alert('Errore nella comunicazione dei dati')
    }
}

return(
<Modal isOpen={modalIsOpen} onRequestClose={()=>setModalIsOpen(false)} shouldCloseOnOverlayClick={false} >
        <h2 style={{marginLeft:"95%"}}>
            <FontAwesomeIcon icon={faTimesCircle} className="exit" role="button" onClick={()=>onClose()}/>
        </h2>
        {ProtocolliConsumer._currentValue.elencoProtocolli !== null && ProtocolliConsumer._currentValue.elencoProtocolli !== undefined && ProtocolliConsumer._currentValue.elencoProtocolli !== allProtocolli ?
        setAllProtocolli(ProtocolliConsumer._currentValue.elencoProtocolli) : null }
        {invioAssegnazione ? <Gestore tipo="assegnaProtocollo" fine={()=>fineAssegnazione()} idpaziente={props.paziente.id} idprotocollo={protocolloAssegnato.ID} settimane={numeroSettimane} giorni={numeroGiorniSettimana} />
            :    
            vediInfoEsercizio===0?
                <div>
                    <Anagrafe {...props} />
                    <Row>
                        <Col>
                            <Button id="1" onClick={(event)=>cambiaFocus(event)} style={{width:"24%",backgroundColor:"#003769",color:"white"}}>PROTOCOLLO IN CORSO</Button>
                            <Button id="2" onClick={(event)=>cambiaFocus(event)} style={{width:"24%",backgroundColor:"#003769",color:"white",marginLeft:"12.5%"}}>CAMBIA PROTOCOLLO ASSEGNATO</Button>
                            <Button id="3" onClick={(event)=>cambiaFocus(event)} style={{width:"24%",backgroundColor:"#003769",color:"white",marginLeft:"12.5%"}}>VISUALIZZA DATI TRAINING</Button>
                        </Col>
                    </Row>
                    {
                    vediSottomenu === "3" ? <SelectComponentTraining estraiProtocollo={(id)=>estraiProtocollo(id)} paziente={props.paziente.id} />
                    :
                    vediSottomenu === "1" ?<SelectComponent {...props} infoEsercizio={(esercizio)=>setVediInfoEsercizio(esercizio)} />
                        :
                        vediSottomenu === "2" ?
                        <Row>
                            <Col md="8">
                                <br/>
                                <TabellaProtocolli sezione="assegnazione" assegna={(protocollo)=>cambiaAssegnazione(protocollo)} setInfoProtocollo={(protocollo)=>setVediInfoProtocollo(protocollo)}/>
                            </Col>
                            {props.paziente.protocollo !== protocolloAssegnato.ID ?
                            <Col>
                                <br/>
                                <Alert variant="info">
                                    Protocollo selezionato : <b>{protocolloAssegnato.titolo}</b>
                                </Alert>
                                <br/>
                                <Row>
                                    <Col md="6" s="12" xs="12">
                                    <Alert variant="primary">
                                        Numero settimane
                                    </Alert>
                                    </Col>
                                    <Col md="6" s="12" xs="12">
                                        <Form.Control type="number" min="1" max="50" id="settimane" value={numeroSettimane} onChange={event=>changeFrequenza(event)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6" s="12" xs="12">
                                        <Alert variant="primary">
                                            Frequenza settimanale
                                        </Alert>
                                    </Col>
                                    <Col md="6" s="12" xs="12">
                                        <Form.Control type="number" min="1" max="7" id="giorni" value={numeroGiorniSettimana} onChange={event=>changeFrequenza(event)} />
                                    </Col>
                                </Row>
                            </Col>
                            :
                            null}
                        </Row>
                        :null
                    }
                    {vediInfoProtocollo===0?null:
                    <InfoProtocollo distinzione="InfoPaziente" protocollo={vediInfoProtocollo} assegnaProtocollo={()=>setProtocolloAssegnato(vediInfoProtocollo)}
                    funzione={()=>setVediInfoProtocollo(0)} ultimoProtocollo={props.paziente.protocollo}/>}
                    <Row>
                        <Col>
                        {props.paziente.protocollo !== protocolloAssegnato.ID && vediSottomenu==="2"?
                        <Button style={{width:"20%",marginLeft:"75%",backgroundColor:"#003769",color:"white"}} onClick={()=>confermaAssegnazione()}>ASSEGNA PROTOCOLLO</Button>:null}
                        <br/><br/>
                        <Button style={{width:"20%",marginLeft:"75%",backgroundColor:"#003769",color:"white"}} onClick={()=>onClose()}>INDIETRO</Button>
                        </Col>
                    </Row>
                </div>
        :
        <InfoEsercizio dist={true} esercizio={vediInfoEsercizio} funzione={()=>setVediInfoEsercizio(0)}/>}
</Modal>
)}