import React,{useState} from 'react'
import {Alert,FormControl,Collapse,Row,Col} from 'react-bootstrap'
import PatologieSpecialita from './PatologieSpecialita'
import {PatologieConsumer} from '../../../../context/PatologieContext'
import {EserciziConsumer} from '../../../../context/EserciziContext'
import Util from './Util'

export default function Gestore(props){
    const [elenco,setElenco]=useState(null)
    const [inizializzato,setInizializzato]=useState(false)
    const [impostaDefault,setImpostaDefault]=useState(false)
    const SPECIALITA = PatologieConsumer._currentValue.elencoPatologie

    const getCheckBoxes = () =>{
        const body=[]
        SPECIALITA.forEach(specialita=>{
            body.push(
            <Col xs="4" key={specialita.idSpecialita}>
                <input type="checkbox" id={specialita.idSpecialita} onClick={(event)=>setElenco(Util.cambiaSpecialitaCheck(elenco,event))} />{specialita.nomeSpecialita}
            </Col>)
        })
        return <Row>{body}</Row>
    }

    const inizializza = () =>{
        const elencoNuovo = []
        if(props.elencoPatologie === null && SPECIALITA !== null){
            SPECIALITA.forEach(specialita=>{
                elencoNuovo.push(Util.elaboraObject(specialita))
            })
        }else{
            props.elencoPatologie.forEach(specialita=>{
                elencoNuovo.push(Util.elaboraObject(specialita,true))
            })
        }

        if(elenco === null && inizializzato === false ) {
            setElenco(elencoNuovo)
            setInizializzato(true)
            if(props.sezione !== 'modifica')
                props.aggiornaSedi(EserciziConsumer._currentValue.elencoSedi)
        }
    }

    const cambiaPatologie = (event) =>{
        var nuovePatologie = Util.cambiaPatologiaCheck(elenco,event);
        setElenco(nuovePatologie);
        props.aggiornaPatologie(nuovePatologie);
    }

    const getBodyPatologie = () =>{
        const body = []

        elenco.forEach(specialitaElenco=>{
            body.push(<PatologieSpecialita key={specialitaElenco.idSpecialita} {...specialitaElenco} cambiaPatologiaCheck={(event)=>cambiaPatologie(event)} />)
        })

        return body
    }

    const impostaDefaultValue = () =>{
        const nuovoElenco = []
        elenco.forEach(specialita=>{
            const patologie = specialita.patologie;
            const arrayPatologie = []
            patologie.forEach(patologia=>{
                var defaultCheck = false;
                if(props.oldPatologie !== null){
                    props.oldPatologie.forEach(associazione=>{ if(associazione.idPatologia === patologia.ID) defaultCheck = true })
                }
                if(defaultCheck === true) patologia.checked = true
                arrayPatologie.push(patologia);
            })
            var objectSpecialita = {
                ...specialita,patologie:arrayPatologie
            }
            nuovoElenco.push(objectSpecialita);
        })
        if(props.sezione === 'modifica') props.aggiornaPatologie(nuovoElenco);
        setImpostaDefault(true);
        setElenco(nuovoElenco);
    }

    return (
        <div style={{border:"1px solid black",padding:"2px"}}>
            {elenco === null && inizializzato === false ? inizializza() 
                :
                props.sezione === "modifica" && impostaDefault === false ?
                    impostaDefaultValue()
                    :
                    null 
                }
            <Row>
                <Col>
                    <Alert  style={{backgroundColor:"lightgrey"}}>
                        Inserisci la/le specialità
                    </Alert>
                </Col>
                <Col>
                    {getCheckBoxes()}
                </Col>
            </Row>
            {elenco !== null && elenco !== undefined  ? getBodyPatologie() : null}
        </div>
    )


}