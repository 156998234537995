import React,{useState} from 'react'
import {Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
//import component
import HeaderTerapista from '../../../StaticComponents/HeaderTerapista'
import TabellaProtocolli from '../Tabelle/TabellaProtocolli'
import InfoProtocollo from '../ModalComponent/InfoProtocollo'

export default function LibreriaProtocolli(){
    const [infoProtocollo,setInfoProtocollo]=useState(0)
    return(
        <div style={{marginLeft:"10%",marginRight:"10%"}}>
            <HeaderTerapista pagina="4"/>
            <br/>
            {infoProtocollo!==0?<InfoProtocollo protocollo={infoProtocollo} funzione={()=>setInfoProtocollo(0)}/>:null}
            <TabellaProtocolli setInfoProtocollo={(protocollo)=>setInfoProtocollo(protocollo)}/>
                <Button style={{width:"20%",marginLeft:"80%",backgroundColor:"#003769",color:"white"}} as={Link} to="/HomeTerapista">
                    HOME
                </Button>
        </div>
    )
}