const checkFiltri = (protocollo,elencoSpecialita,cercaTitolo,cercaSettimane,cercaPatologie) =>{
    
    var filtroTitolo = cercaTitolo.toUpperCase();
    var protocolloTitolo = protocollo.titolo.toUpperCase();
    var checkTitolo = false
    if(protocolloTitolo.indexOf(filtroTitolo) > -1 ) checkTitolo = true

    var protocolloSettimane = protocollo.numeroSettimane;
    const settimaneMeno =  cercaSettimane-1
    const settimanePiu =  Number(settimaneMeno)+2
    var checkSettimane  = false;
    if((settimaneMeno <= protocolloSettimane &&  protocolloSettimane <= settimanePiu) || cercaSettimane==="") checkSettimane = true

    var associazionePatologie = protocollo.patologie
    var patologieFiltro =  cercaPatologie.toUpperCase();
    var testoSpace = ""
    var testoNoSpace = ""
    var checkPatologie = false;
    if(patologieFiltro === ""){
        checkPatologie = true
    }else{
        associazionePatologie.forEach(associazionePatologie=>{
            elencoSpecialita.forEach(specialita=>{
                var elencoPatologie = specialita.patologia
                elencoPatologie.forEach(patologiaCont=>{
                    if(associazionePatologie.idPatologia === patologiaCont.ID){
                        if(testoNoSpace===""){
                            testoSpace = testoSpace + patologiaCont.nome
                            testoNoSpace = testoNoSpace + patologiaCont.nome
                        }else{
                            testoSpace = testoSpace +" / "+ patologiaCont.nome
                            testoNoSpace = testoNoSpace +"/"+ patologiaCont.nome
                        }
                    } 
                }) 
            })
        })
        testoNoSpace = testoNoSpace.toUpperCase();
        testoSpace = testoSpace.toUpperCase();
        if(testoSpace.indexOf(patologieFiltro)> -1 || testoNoSpace.indexOf(patologieFiltro)>-1) checkPatologie = true
    }

    if(checkTitolo === false || checkSettimane === false || checkPatologie === false) return false
    else return true
}

const getMessaggioPatologie = (protocollo,elencoSpecialita) =>{
    const patologieProtocollo = protocollo.patologie
    var testoPatologie = ""
    patologieProtocollo.forEach(associazione=>{
        elencoSpecialita.forEach(specialita=>{
            specialita.patologia.forEach(patologia=>{
                if(associazione.idPatologia === patologia.ID){
                    if(testoPatologie===""){
                        testoPatologie = patologia.nome
                    }else{
                        testoPatologie = testoPatologie + " / " + patologia.nome
                    }
                }
            })//patologia
        })//specialita
    })//associazione

    return testoPatologie
}

const getProtocolloDaSalvare = (props) =>{
    
    //ELABORAZIONE PATOLOGIE
    var patologieSelezionate = ""
    const SPECIALITA = props.patologie;
    SPECIALITA.forEach((specialita)=>{
        const PATOLOGIE = specialita.patologie;
        PATOLOGIE.forEach((patologia)=>{
            if(patologia.checked){
                if(patologieSelezionate === "") {
                    patologieSelezionate = patologia.ID
                }else{
                    patologieSelezionate = patologieSelezionate + "$" +patologia.ID
                }  
            }
        })
    })

    //ELABORAZIONE ESERCIZI
    const eserciziScelti = []
    var contatore = 0
    props.eserciziScelti.forEach((esercizio)=>{
        const ripetizioniEsercizio = props.elencoRipetizioni[contatore];
        var esercizioSelezionato = {
            id : esercizio.ID,
            ripetizioni : ripetizioniEsercizio
        }
        eserciziScelti.push(esercizioSelezionato);
        contatore++
    })

    var objectSave = {
        titolo : props.titolo,
        descrizione : props.descrizione,
        livello : props.difficolta,
        frequenza : props.frequenza,
        settimane :  props.numeroSettimane,
        patologie : patologieSelezionate,
        esercizi : eserciziScelti
    }

    return objectSave
}

export default { checkFiltri , getMessaggioPatologie , getProtocolloDaSalvare}