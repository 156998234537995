import React from 'react'
import {Form,Col,Row} from 'react-bootstrap'
import UtilGestione from '../Utill/UtilGestionePazienti'
import Visualizza from './VisualizzaDato'

export default function Anagrafe(props){

    return (
        <h4>
            <br/>
            <Visualizza nome="Cognome" valore={props.paziente.cognome}/>
            <Visualizza nome="Nome" valore={props.paziente.nome}/>
            <Visualizza nome="Codice Fiscale" valore={props.paziente.codiceFiscale}/>
            <Visualizza nome="Data di Nascita" valore={UtilGestione.getDataNacita(props.paziente.dataNascita)}/>
            <Visualizza nome="Luogo di Nascita" valore={props.paziente.luogoNascita}/>
            <Visualizza nome="Email" valore={props.paziente.email}/>
            <Visualizza nome="Telefono" valore={props.paziente.telefono}/>
        </h4>
    )
}