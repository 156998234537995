import React,{useState,useContext} from 'react'
import {EserciziContext,EserciziConsumer} from '../../../../context/EserciziContext'
import {ProtocolliContext,ProtocolliConsumer} from '../../../../context/ProtocolliContext'
import Loader from '../../../../Loader'
import {Alert,Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Salva(props){

    const buttonStyle = {backgroundColor:"#003769",color:"white",width:"20%",marginLeft:"80%"}

    const { saveEsercizio , getAllEsercizi } = useContext(EserciziContext);
    const { saveProtocollo , getAllProtocolli } =useContext(ProtocolliContext);
    const[loading,setLoading] = useState(false);
    const[apiChiamata,setApiChiamata] = useState(false);
    const[errore,setErrore] = useState(false);
    const[salvataggioEseguito,setSalvataggioEseguito] = useState(false);

    const doChiamata = () =>{
        setLoading(true)
        if(props.tipo==="esercizio") doSalvaEsercizio()
        if(props.tipo==="protocollo") doSalvaProtocollo()
    }

    const doSalvaEsercizio =async () =>{
        if(EserciziConsumer._currentValue.ultimoSave !== props.oggetto){
            try{ 
                await saveEsercizio(props.oggetto).then(async function(){
                    doGetAllEsercizi()
                })
            }catch(error){
                erroreChiamata(error)
            }
        }
    }

    const doGetAllEsercizi = async ()=>{
        try{
            await getAllEsercizi().then(function(){
                successoChiamata()
            })
        }catch(error){
            erroreChiamata(error)
        }
    }

    const doSalvaProtocollo =async () =>{
        if(ProtocolliConsumer._currentValue.ultimoSave !== props.oggetto){
            try{ 
                await saveProtocollo(props.oggetto).then(async function(){
                    doGetAllProtocolli()
                })
            }catch(error){
                erroreChiamata(error)
            }
        }
    }

    const doGetAllProtocolli = async ()=>{
        try{
            await getAllProtocolli().then(function(){
                successoChiamata()
            })
        }catch(error){
            erroreChiamata(error)
        }
    }

    const successoChiamata = () =>{
        setLoading(false)
        setApiChiamata(true)
        setErrore(false)
        setSalvataggioEseguito(true)
    }

    const erroreChiamata = (error) =>{
        console.error(error);
        setLoading(false)
        setApiChiamata(true)
        setErrore(true)
        setSalvataggioEseguito(true)
    }

return(
    <div>
        {salvataggioEseguito ?
            <div>
                <Alert variant="success" >salvataggio eseguito con successo</Alert>
            </div>
            :
            loading===true ? 
                <Loader sezione="m"/>
                :
                errore===true ? <Alert variant="danger">Errore nel tentativo di salvataggio,ricaricare la pagina e riprovare</Alert>
                    :
                    apiChiamata===true ? null 
                        : 
                        doChiamata()
            }           
            {!loading ? 
                errore ?
                    <Button style={buttonStyle} onClick={()=>props.indietro()}> INDIETRO </Button>
                    :
                    <Link to='/HomeTerapista'>
                        <Button style={buttonStyle}> HOME </Button>
                    </Link>
                :
                null
                }
        </div>
)}