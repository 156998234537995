import React from 'react'
import {Button} from 'react-bootstrap'

export default function GestisciMedici(props){
    return(
        <div>
            Gestione dei Medici
            <Button variant="danger" onClick={props.setSceltaMenu}>INDIETRO</Button>
        </div>
    )
}