import React from 'react'
import {Row,Col,Dropdown,Alert} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'

export default function HeaderPaziente(){
    
    return(
        <Alert style={{marginBottom:"0px",width:"100%",position:"fixed",backgroundColor:"white"}}>
          <Row>
              <Col md="8">
                <h1>ADIUVANT</h1>
              </Col>
              <Col md="4">
                <h3>
                <Dropdown>
                    <Dropdown.Toggle id="dropdown" style={{backgroundColor:"white",color:"black",width:"40%",fontSize:"80%"}}>
                        <FontAwesomeIcon icon={faThLarge}/>&ensp;MENU
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link}to="/">
                                Logout
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </h3>
              </Col>
            </Row>  
        </Alert>
    )
}