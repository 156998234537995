import React from 'react'
import {Alert,Row,Col,Collapse} from 'react-bootstrap'
export default function PatologieSpecialita(props){

    const getCheckBoxes = () =>{
        const body = []
        props.patologie.forEach((patologia)=>{
            body.push(<Col md="4" key={patologia.ID}>
                <input type="checkbox" value={patologia.checked} id={patologia.ID} onChange={(event)=>props.cambiaPatologiaCheck(event)} checked={patologia.checked}/>{patologia.nome}
                </Col>)
        })
        return <Row>{body}</Row>
    }

    return (
        <>
            <Collapse in={props.visibile}>
                <Row>
                    <Col md="6">
                        <Alert  style={{backgroundColor:"lightgrey"}}>
                            Diagnosi{" "+props.nomeSpecialita}
                        </Alert>
                    </Col>
                    <Col>
                        {getCheckBoxes()}
                    </Col>
                </Row>
            </Collapse>
        </>
    )

}