import React,{useState} from 'react'

import {Button,Alert} from 'react-bootstrap'
import {PazienteConsumer} from '../../context/PazienteContext'
import Gestore from '../Gestore/Gestore'

export default function FineAttivita(props){

    const [datiInviati,setDatiInviati] = useState(false);
    const [objectSave,setObjectSave] = useState(null)

    const creaOggetto = () =>{
        
        var durata = getDurataTraining('secondi')

        var oggetto = {
            paziente : PazienteConsumer._currentValue.idPaziente,
            protocollo : props.protocollo.protocollo.ID,
            oraInizio : props.inizio.getTime(),
            oraFine : props.fine.getTime(),
            durata : durata
        }
        setObjectSave(oggetto)
    }

    const getDurataTraining = (richiesta) =>{
        var secondiInizio = (props.inizio.getTime()/1000).toFixed(0);
        var secondiFine = (props.fine.getTime()/1000).toFixed(0);
        var differenza = secondiFine - secondiInizio
        var secondi = differenza % 60
        var minuti = (differenza-secondi)/60

        if(richiesta==='complete') return minuti+" minuti e "+secondi+" secondi"
        if(richiesta==='secondi') return differenza
    
    }

    return(
        <>
            { objectSave === null ? creaOggetto() :
                datiInviati === false ? <Gestore training={objectSave} tipo="save" setCaricando={(valore)=>{}} setDatiInviati={()=>setDatiInviati(true)} />
                :
                <div>
                    <br/><br/>
                    <Alert variant="success">Esercitazione ultimata.<br/>Durata del training : {getDurataTraining('complete')}</Alert>
                    <br/>
                    <Button variant="success" onClick={()=>props.nuovaSessione()}>INIZIA NUOVA SESSIONE</Button>
                    <br/><br/>
                </div>
            }
        </>  
    )
}