import React,{useState} from 'react'
import {Button,Alert} from 'react-bootstrap'
import Salva from './Salva'
import Uploader from '../../../VimeoComponents/Uploader/Uploader'
export default function RecapEsercizio(props){

    const buttonStyle = {backgroundColor:"#003769",color:"white",width:"20%",marginLeft:"80%"}
    const [salva,setSalva]=useState(false);
    const [uploadEseguito,setUploadEseguito]=useState(false);
    const [oggettoSave,setOggettoSave]=useState(null);
    const [erroreUpload,setErroreUpload]=useState(false);

    const descrizione = "esercizio : "+props.esercizio.titolo+" - "+new Date().getDate()+"/"+(new Date().getMonth()+1)+"/"+new Date().getFullYear()

    const getSediInteressate = () =>{
        const body = [];
        const stringSedi =String(props.esercizio.saveObject.sedi)
        const elencoSedi = props.esercizio.sedi; 
        const elencoSediEsercizio = stringSedi.split('$');
        elencoSediEsercizio.forEach(sedeEsercizio=>{
            elencoSedi.forEach(sedeElenco=>{
                if( sedeElenco.ID === Number(sedeEsercizio) ) body.push(<li key={"Sede"+sedeElenco.ID}>{sedeElenco.nome}</li>)
            })
        })
        return <ul key="sedi">{body}</ul>
    }

    const getPatologieInteressate = () =>{
        const body=[]
        const stringPatologie = String(props.esercizio.saveObject.patologie)
        const elencoSpecialita = props.esercizio.elencoPatologie
        const elencoPatologieEsercizio = stringPatologie.split('$');
        elencoSpecialita.forEach((specialita)=>{
            const bodySpecialita = []
            elencoPatologieEsercizio.forEach((patoEsercizio)=>{
                const elencoPatologieSpecialita = specialita.patologie;
                elencoPatologieSpecialita.forEach((patoSpec)=>{
                    if(Number(patoEsercizio) === patoSpec.ID) bodySpecialita.push(<li key={"Patologia"+patoSpec.ID}>{patoSpec.nome}</li>)
                })
            })
            if(bodySpecialita.length>0){
                body.push(<div key={"Specialita"+specialita.idSpecialita}>
                    Patologie della specialità <b>{" "+specialita.nomeSpecialita}</b>
                    <br/>{bodySpecialita}<br/>
                </div>)
            }
        })
        return body
    }

    const uploadEseguitoFunction = (linkVideoSalvato) =>{
        var newLink="";
        if(linkVideoSalvato === 'mod') newLink = "";
        else newLink = linkVideoSalvato;
        var nuovoOggetto = {...props.esercizio.saveObject,url:newLink}
        setOggettoSave(nuovoOggetto);
        setUploadEseguito(true);
    }

    const getDurata = () =>{
        var durataEsercizio = props.esercizio.durata
        var secondi = durataEsercizio % 60
        var minuti = (durataEsercizio - secondi)/60
        if(minuti > 0) return minuti+" minuti e "+secondi+" secondi"
        else return secondi+" secondi"
    }

    return (
        erroreUpload === true ?
            <Alert variant="danger">Errore nel caricamento del video,riprovare</Alert>
            :
            salva === true ? 
                props.sezione === "modifica" ?
                        uploadEseguito === true ?
                            <Salva oggetto={oggettoSave} file={props.fileVideo} tipo="esercizio" indietro={()=>setSalva(false)}/>
                            :
                            uploadEseguitoFunction('mod')
                    :
                uploadEseguito === false ?
                <Uploader fileVideo={props.fileVideo} titolo={props.esercizio.titolo} descrizione={descrizione} 
                erroreUpload={()=>setErroreUpload(true)} uploadEseguito={(link)=>uploadEseguitoFunction(link)} />
                :
                <Salva oggetto={oggettoSave} file={props.fileVideo} tipo="esercizio" indietro={()=>setSalva(false)}/>
            :
            <div>
                <Alert style={{backgroundColor:"#003769",color:"white"}}>
                    <b>Conferma dei parametri inseriti</b>
                </Alert>
                <br/>
                <Alert style={{backgroundColor:"lightgray"}}>
                    Titolo :
                    <b>{props.esercizio.titolo}</b>
                </Alert>
                <hr/>
                <Alert style={{backgroundColor:"lightgray"}}>
                    Descrizione :
                    <br/>
                    <b>{props.esercizio.descrizione}</b>
                </Alert>
                <hr/>
                <Alert style={{backgroundColor:"lightgray"}}>
                    Istruzioni :
                    <br/>
                    <b>{props.esercizio.istruzioni}</b>
                </Alert>
                <hr/>
                <Alert style={{backgroundColor:"lightgray"}}>
                    Durata :
                    <b>{getDurata()}</b>
                </Alert>
                <hr/>
                <Alert style={{backgroundColor:"lightgray"}}>
                    Fase di training :
                    <b>{props.esercizio.faseTraining}</b>
                </Alert>
                <hr/>
                <Alert style={{backgroundColor:"lightgray"}}>
                    Livello :
                    <b>{props.esercizio.livelloDifficolta}</b>
                </Alert>
                {props.sezione === "modifica" ? null :
                    <div>
                        <hr/>
                        <Alert style={{backgroundColor:"lightgray"}}>
                            Video associato :
                            <b>{props.esercizio.nomeFileVideo}</b>
                        </Alert>
                    </div>
                }
                <hr/>
                <Alert style={{backgroundColor:"lightgray"}}>
                    Elenco sedi interessate :<br/>
                </Alert>
                {getSediInteressate()}
                <hr/>
                <Alert style={{backgroundColor:"lightgray"}}>
                    Elenco patologie interessate
                </Alert>
                <br/>
                {getPatologieInteressate()}
                <hr/>
                <Button onClick={()=>setSalva(true)} style={buttonStyle}>CONFERMA</Button><br/><br/>
                <Button onClick={()=>props.indietro()} style={buttonStyle}>INDIETRO</Button>
            </div>
    )
}