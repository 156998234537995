import React,{useState} from 'react'
import {Button,Table,Row,Col,Form, Alert} from 'react-bootstrap'
import Util from '../Utill/UtilProtocolli'
import Salva from'./Salva'
import GestioneEserciziVideo from '../ModalComponent/GestioneEserciziVideo'

import './Style.css'

export default function RecapProtocollo(props){

    const [salva,setSalva] = useState(null)

    const elencoEsercizi=[]
    
    const getEsercizi = () =>{
        //componimneto Header
        const headerTabella=[]
        const rowHeader=[]
        rowHeader.push(<th key="HeaderRecapTitolo">Titolo</th>)
        rowHeader.push(<th key="HeaderRecapDifficolta">Difficoltà</th>)
        rowHeader.push(<th key="HeaderRecapFase">Fase Training</th>)
        rowHeader.push(<th key="HeaderRipetizioni">Ripetizioni</th>)
        headerTabella.push(<tr key="HeaderRecapRow">{rowHeader}</tr>)
        elencoEsercizi.push(<thead key="theadRecap">{headerTabella}</thead>)
        //componimento Body
        const rows=[]
        var contatore=0
        props.eserciziScelti.forEach((esercizio)=>{
            const row=[]
            row.push(<td key={contatore+"RecapTitolo"}>{esercizio.titolo}</td>)
            row.push(<td key={contatore+"RecapDifficolta"}>{esercizio.livello}</td>)
            row.push(<td key={contatore+"RecapFase"}>{esercizio.faseTraining}</td>)
            row.push(<td key={contatore+"Ripetizione"}>{props.elencoRipetizioni[contatore]}</td>)
            rows.push(<tr key={contatore+"RowRecap"}>{row}</tr>)
            contatore++
        })
        elencoEsercizi.push(<tbody key="tbodyRecap">{rows}</tbody>)
        return <Table striped bordered hover className="TabellaEsercizi">{elencoEsercizi}</Table>
    }

    const getPatologie = () =>{
        const body=[]
        const SPECIALITA = props.patologie
        SPECIALITA.forEach((specialita)=>{
            const PATOLOGIE = specialita.patologie
            const bodySpecialita = []
            PATOLOGIE.forEach(patologia=>{
                if(patologia.checked) bodySpecialita.push(<li key={patologia.ID+"patologia"}>{patologia.nome}</li>)
            })
            if(bodySpecialita.length>0){
                body.push(<div key={"Specialita"+specialita.idSpecialita}>
                    Patologie della specialità <b>{" "+specialita.nomeSpecialita}</b>
                    <br/>{bodySpecialita}<br/>
                </div>)
            }
        })
        return body
    }

    const invio=()=>{
        var protocollo = Util.getProtocolloDaSalvare(props);
        if(props.idProtocollo !== null) protocollo.ID = props.idProtocollo
        setSalva(protocollo)
    }

    const stileButton={width:"20%",marginLeft:"80%",backgroundColor:"#003769",color:"white"}

    return(
        <div>
        {salva !== null ?
            <Salva oggetto={salva} tipo="protocollo" indietro={()=>setSalva(null)}/>
            :
            <div>
                <Alert style={{backgroundColor:"#003769",color:"white"}}>
                    <b>Conferma dei parametri inseriti</b>
                </Alert>
                <Row>
                    <Col>
                    <h5>
                    <br/>
                    <Row>
                        <Col>Titolo</Col>
                        <Col>
                            <Form.Control type="text" readOnly="readonly" className="FormControlRead" defaultValue={props.titolo}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>Descrizione</Col>
                        <Col>
                            <Form.Control as="textarea" readOnly="readOnly" className="FormControlRead" rows="4" defaultValue={props.descrizione}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>Difficoltà</Col>
                        <Col>
                            <Form.Control type="text" readOnly="readonly" className="FormControlRead" defaultValue={props.difficolta}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>Numero di settimane</Col>
                        <Col>
                            <Form.Control type="text" readOnly="readonly" className="FormControlRead" defaultValue={props.numeroSettimane}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>Frequenza settimanale</Col>
                        <Col>
                            <Form.Control type="text" readOnly="readonly" className="FormControlRead" defaultValue={props.frequenza}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>Durata totale</Col>
                        <Col>
                            <Form.Control type="text" readOnly="readonly" className="FormControlRead" defaultValue={props.messaggioDurataTotale}/>
                        </Col>
                    </Row>
                    <br/>
                    <hr/>
                    <Alert style={{backgroundColor:"lightgray"}}>Patologie associate</Alert>
                    {getPatologie()}
                    </h5>
                    </Col>
                    <Col>
                    <h4>
                        <br/>
                        Elenco esercizi selezionati
                    </h4>
                    {getEsercizi()}
                    </Col>
                </Row>
                <br/>
                <Alert style={{backgroundColor:"#003769",color:"white"}}><b>ANTEPRIMA DI VISUALIZZAZIONE</b></Alert>
                <br/> 
                <GestioneEserciziVideo esercizi={props.eserciziScelti} primoEsercizio={props.eserciziScelti[0]} primoVideo={props.eserciziScelti[0].urlVideo}/>           
                <br/>
                <Button style={stileButton} onClick={()=>invio()}>CONFERMA</Button>
                <br/><br/>
                <Button style={stileButton} onClick={()=>props.indietro()}>INDIETRO</Button>
                <br/><br/><br/>
            </div>
        }       
        </div>
    )
}