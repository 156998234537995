import React,{useState,useContext} from 'react'
import MenuDottore from '../Interfaccia-Medico/Components-Medico/MenuDottore'
import HeaderTerapista from '../Interfaccia-Medico/StaticComponents/HeaderTerapista'
import Loader from '../Loader'

import { PatologieContext,PatologieConsumer } from '../context/PatologieContext'
import { EserciziContext,EserciziConsumer } from '../context/EserciziContext'
import { ProtocolliContext,ProtocolliConsumer } from '../context/ProtocolliContext'

export default function HomeDottore(props){

    const { getAllPatologie } = useContext(PatologieContext);
    const { getAllEsercizi } = useContext(EserciziContext);
    const { getAllProtocolli } = useContext(ProtocolliContext);

    const[loading,setLoading] = useState(false);
    const[apiChiamata,setApiChiamata] = useState(false);
    const[errore,setErrore] = useState(false);

    const doChiamataProtocolli = async () =>{
        setLoading(true);
        try{
            await getAllProtocolli().then(async function(){
                doChiamataEsercizi()
            })
        }catch(error){ configError(error) }
    }

    const doChiamataEsercizi = async () =>{
        try{
            await getAllEsercizi().then(async function(){ doChiamataPatologie() })
        }catch(error){ configError(error) }
    }

    const doChiamataPatologie = async () =>{  
        try{
            await getAllPatologie().then(function(){ successoChiamata() })
        }catch(error){ configError(error) }
    }
    

    const configError = (error) =>{
        console.log(error)
        setLoading(false)
        setApiChiamata(true)
        setErrore(true)
    }

    const successoChiamata = () =>{
        setLoading(false)
        setApiChiamata(true)
    }
    

    return(
        <div style={{marginLeft:"10%",marginRight:"10%"}}>
            {
                PatologieConsumer._currentValue.inizializzato ?
                <div>
                    <HeaderTerapista user={props.user} pagina="5"/>
                    <MenuDottore/>
                </div>
                :
                <div>
                    {loading===true ? <Loader sezione="m"/>
                        :
                        errore===true ? <>Errore...</>
                            :
                            apiChiamata===true ? null : doChiamataProtocolli()
                    }
                </div>
            }           
        </div>
    )  

}